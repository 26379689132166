<div class="modal-header">
    <h5 class="modal-title">Dossier médicale - Allergie</h5>
    <button
    type="button"
    class="btn-close exit-button"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ><i class="fa-regular fa-circle-xmark"></i>
  </button>
  </div>
       
  <div class="modal-body">
    <div>
     <div class="form-row">
            <div class="col">
                <div class="form-group text-center">
                    <p>Êtes-vous sûr(e) de vouloir supprimer cette allergie ?</p>
                     
                </div>
            </div>        
        </div>
         
                    <div class="form-group text-center mt-2">
                        <button (click)="cancel()" type="reset" class="btn btn-secondary mr-3">Annuler</button>
                        <button  type="submit" (click)="onSubmit()" class="btn btn-primary">Suuprimer</button>
                    </div>

</div>
</div>