import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { LoginGuard } from "./_guards/login.guard";
import { ResetPassowrdComponent } from "./components/reset-passowrd/reset-passowrd.component";
import { UpdatePassowrdComponent } from "./components/update-passowrd/update-passowrd.component";
import { DashboardComponent } from "./patient/dashboard/dashboard.component";
import { DocumentsComponent } from "./patient/files/documents/documents.component";
import { RdvsComponent } from "./patient/rdvs/rdvs.component";
import { MedecinComponent } from "./patient/medecin/medecin.component";
import { CalendarComponent } from "./patient/calendar/calendar.component";
import { ProfilComponent } from "./patient/profil/profil.component";
import { AuthGuard } from "./_guards/auth.guard";
import { AdminGuard } from "./_guards/admin-guard.guard";
import { CertificatComponent } from "./patient/files/certificat/certificat.component";
import { OrdonnanceComponent } from "./patient/files/ordonnance/ordonnance.component";
import { FillInscriptionComponent } from "./components/fill-inscription/fill-inscription.component";
import { AddDocumentComponent } from "./patient/files/documents/add-document/add-document.component";
import { ValidationMedecinComponent } from "./patient/validation-medecin/validation-medecin.component";
import { UpdateDocumentComponent } from "./patient/files/documents/update-document/update-document.component";


const routes: Routes = [
  {
    path: "",
    redirectTo: "/connexion",
    pathMatch: "full",
    canActivate: [LoginGuard],
  },
  { path: "connexion", component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: "mot-de-passe-oublie",
    component: ResetPassowrdComponent,
    canActivate: [LoginGuard],
  },
  {
    path: "terminez-votre-inscription/:token",
    component: FillInscriptionComponent,
  },
  {
    path: "reinitialisation/:token",
    component: UpdatePassowrdComponent,
  },
  {
    path: "patient",
    component: DashboardComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/rendez-vous",
    component: RdvsComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/medecins",
    component: MedecinComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/documents",
    component: DocumentsComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/documents/ajout",
    component: AddDocumentComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/documents/modification",
    component: UpdateDocumentComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/certificats",
    component: CertificatComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/ordonnance",
    component: OrdonnanceComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/agenda",
    component: CalendarComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: "patient/profil",
    component: ProfilComponent,
    canActivate: [AuthGuard, AdminGuard],
  },

  {
    path: "patient/Validation-medecin/:id",
    component: ValidationMedecinComponent,
    canActivate: [AuthGuard, AdminGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
