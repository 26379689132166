<div class="modal-header">
    <h5 class="modal-title">Dossier médicale - Vaccins</h5>
    <button
    type="button"
    class="btn-close exit-button"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ><i class="fa-regular fa-circle-xmark"></i>
  </button>
  </div>
       
  <div class="modal-body">
    <div>
     <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
     <div class="form-row">
            <div class="col">
                <div class="form-group">
                    <label>Vaccins:</label>
                    <ng-select [items]="vaccins"
                    class="form-control "
                    [closeOnSelect]="true"
                    formControlName="vaccins"
                    [(ngModel)]="vacs"
                    multiple = true 
                    >
                   </ng-select>      
                </div>
            </div>        
        </div>
         
                    <div class="form-group text-center mt-2">
                        <button (click)="cancel()" type="reset" class="btn btn-secondary mr-3">Annuler</button>
                        <button type="submit" class="btn btn-primary">Enregistrer</button>
                    </div>
    </form>

</div>
</div>
       

