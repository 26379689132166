<app-header></app-header>
<div class="container-fluid pl-sm-5 pr-sm-5 bg-light">
    <div class="row">
        <div class="col-lg-12 mt-3">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/"><i class="fa-solid fa-house"></i>
                        Accueil</a>
                </li>
                <li class="breadcrumb-item active">Profile</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 d-flex">
            <div class="card p-3 w-100">
                <div class="card-body">
                    <div *ngIf="user"  class="d-flex flex-column align-items-center text-center">
                        <div>
                            <div class="profile-image-container">
                              <div class="profile-image">
                                <input type="file" id="fileInput" style="display: none;" accept="image/*" (change)="onFileSelected($event)">
                                <label for="fileInput" class="custom-file-upload">
                                  <i class="fa fa-camera change-photo-text"></i>
                                  <span class="change-photo-text">Changer la photo</span>
                                </label>
                                <!-- <img *ngIf="user.image" src="{{ BaseUrl }}{{user._id}}/{{ user.image }}" alt="Uploaded Image" /> -->
                                <img [src]="imageSrc" alt="Uploaded Image" />
                              </div>
                            </div>                                   
                          </div>
                          
                      <div class="mt-3">
                        <h4 style="text-transform: capitalize;">{{user.prenom}} {{user.nom}}</h4>
                        <span class="badge bg-blue p-2">{{user.idPatient}}</span>
                        <span></span>                    
                      </div>
                    </div>
                  </div>
            </div>
        </div>
        <div class="col-md-9 d-flex">
            <div class="card p-3 w-100">
                <div class="card-body">
                    <div *ngIf="user" class="row">
                        <div class="col-md-3">
                            <div class="custom-card d-flex flex-column h-100">
                                <div class="card-header text-left">
                                    <span style="color:#0090b2; font-size: 1.4rem;">
                                        <i class="fa-solid fa-weight-scale" style="color:#0090b2; font-size: 2rem; margin-right: 10px;"></i> Poids
                                    </span>
                                </div>
                                <div class="card-body text-center">
                                    <h4 style="color:#039be5;">{{user.poids}} kg</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="custom-card d-flex flex-column h-100" >
                                <div class="card-header text-left">
                                    <span style="color:#0090b2; font-size: 1.4rem;">
                                        <i class="fa-solid fa-ruler-vertical" style="color:#0090b2; font-size: 2rem; margin-right: 10px;"></i> Taille
                                    </span>
                                </div>
                                <div class="card-body text-center">
                                        <h4 style="color:#039be5;">{{user.taille}} cm</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="custom-card d-flex flex-column h-100">
                                <div class="card-header text-left">
                                    <span style="color:#0090b2; font-size: 1.4rem;">
                                        <i class="fa-solid fa-gauge" style="color:#0090b2; font-size: 2rem; margin-right: 10px;"></i> IMC
                                    </span>
                                </div>
                                <div class="card-body text-center">
                                    <h4 style="color:#039be5;">{{imc}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="custom-card d-flex flex-column h-100">
                                <div class="card-header text-left">
                                    <span style="color:#0090b2; font-size: 1.4rem;">
                                        <i class="fa-solid fa-droplet" style="color:#0090b2; font-size: 2rem; margin-right: 10px;"></i> Groupe sanguin
                                    </span>
                                </div>
                                <div class="card-body text-center">
                                    <h4 style="color:#039be5;">{{user.groupeSanguin}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-md-12 d-flex">
            <div class="card p-3 w-100">
                <div class="card-header">
                    <ul class="nav nav-tabs card-header-tabs ">
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="selectedTab === 'form1'" (click)="selectTab('form1')">Informations générales</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="selectedTab === 'form2'" (click)="selectTab('form2')">Dossier médicale</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="selectedTab === 'form3'" (click)="selectTab('form3')">Mot de passe</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <app-general-info-form *ngIf="selectedTab === 'form1'" [user]="user"></app-general-info-form>
                    <app-health-details-form *ngIf="selectedTab === 'form2'" [user]="user"></app-health-details-form>
                    <app-password-form *ngIf="selectedTab === 'form3'" [user]="user" (passwordChange)="onPasswordChange()"></app-password-form>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>