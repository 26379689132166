import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { DocumentService } from 'src/app/_services/document.service';
import { MedecinPatientService } from 'src/app/_services/medecin-patient.service';

@Component({
  selector: 'app-popup-partage-document',
  templateUrl: './popup-partage-document.component.html',
  styleUrls: ['./popup-partage-document.component.css']
})
export class PopupPartageDocumentComponent implements OnInit {

  user: any;
  imc  : any;
  medecinsTraitants :any
  sizeContent: any;
  listMedecinsToSended = []
  idDoc
  listMedecinsPartages = []
  document :any ={} 
  constructor(
    private medecinPatientService :MedecinPatientService,
    private documentService :DocumentService,
    public modal: NgbActiveModal,
    private router: Router,
    public iziToast: Ng2IzitoastService,

  ) { }

  ngOnInit(): void {
    this.listesMedecinsPartages()
    this.getMedecinsTraitants()
  }

  getMedecinsTraitants(){
    this.medecinPatientService.getMedecinsTraitants().subscribe(data => {
    this.medecinsTraitants = data
    this.sizeContent=this.medecinsTraitants.length
  })
  }

  listesMedecinsPartages(){
    this.documentService.getListesMedecinsPartages(this.idDoc).subscribe(data => {
      this.listMedecinsPartages = data
      this.listMedecinsToSended = this.listMedecinsPartages
    })
  }
  getLiStDoctorsToShare(medecin) {
    const index = this.listMedecinsToSended.indexOf(medecin._id);
    if (index === -1) {
        this.listMedecinsToSended.push(medecin._id);
    } else {
        this.listMedecinsToSended.splice(index, 1);
    }
   }

   shareDocment(){
    this.document.listMedecinsPartages = this.listMedecinsToSended
    this.documentService.shareDocment(this.idDoc,this.document).subscribe(data => {
      this.iziToast.show({
        message: "Le partage est effectué avec succès",
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
      this.modal.dismiss();
    })
   }





}
