import { Component, OnInit } from '@angular/core';
import { MedecinPatientService } from 'src/app/_services/medecin-patient.service';
import villesTunisie from "src/app/_helpers/villes.json";
import specialite from "src/app/_helpers/specialites.json"
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupAddAppointmentComponent } from 'src/app/popus/rendez-vous/popup-add-appointment/popup-add-appointment.component';
@Component({
  selector: 'app-medecin',
  templateUrl: './medecin.component.html',
  styleUrls: ['./medecin.component.css']
})

export class MedecinComponent implements OnInit {
  medecinsTraitants :any
  villes: string[];
  specialiites: string[];
  constructor(
    private medecinPatientService :MedecinPatientService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.villes= villesTunisie
    this.specialiites= specialite
    this.getMedecinsTraitants()
  }

  getMedecinsTraitants(){
    this.medecinPatientService.getMedecinsTraitants().subscribe(data => {
    this.medecinsTraitants = data
  })
  }
  addAppointment(medecin){
    const modalRef = this.modalService.open(PopupAddAppointmentComponent);
    modalRef.componentInstance.medecin = medecin;
  }

}
