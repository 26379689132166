import { Component, OnInit } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SharedService } from 'src/app/_services/shared.service';
import { UserService } from 'src/app/_services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {
  user: any;
  test = { "nom": "heni", "prenom": "rrr" }
  imc: any;
  selectedTab: string = 'form1';
  BaseUrl = environment.baseUrl.split('/api')[0] + '/uploads/profilPatient/';
  imageUrlReadrer: string;
  fileUploaded
  imageSrc: string = 'https://www.w3schools.com/w3css/img_avatar3.png';
  selectedFile: any;


  constructor(private userService: UserService,public iziToast: Ng2IzitoastService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.userService.getUser().subscribe(user => {
      this.user = user;
      let poids = parseFloat(this.user.poids);
      let taille = parseFloat(this.user.taille);
      this.imc = poids / ((taille / 100) ** 2);
      this.imc = this.imc.toFixed(2);
      this.imageSrc = this.BaseUrl+this.user._id+'/'+this.user.image
      if(!this.containsImageExtension(this.imageSrc))
      this.imageSrc = 'https://www.w3schools.com/w3css/img_avatar3.png';
    });
    this.sharedService.imcUpdated$.subscribe((imc: number) => {
      this.imc = imc.toFixed(2);
    });
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      // Vérifiez la taille du fichier (2 Mo = 2 * 1024 * 1024 octets)
      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
          this.iziToast.show({
            message:"L'image ne doit pas dépasser la taille maximale 2 Mo.'",
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',	
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            //overlay:true,
            overlayClose:true,	
           });
          return;
      }

      // Vérifiez le type de fichier (images uniquement)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp'];
      if (!allowedTypes.includes(file.type)) {
          this.iziToast.show({
            message: 'L\’image doit être de type png, jpg , jpeg ou webp.',
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',	
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            //overlay:true,
            overlayClose:true,	
           });
          return;
      }

      this.selectedFile = file;
      const reader = new FileReader();

      reader.onload = () => {
        this.imageSrc = reader.result as string;
      };

      reader.readAsDataURL(file);
      this.uploadImage()
      this.majImage()
    }
  }

  uploadImage(): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('img', this.selectedFile);
      this.userService.uploadImage(formData,this.user._id).subscribe(data => {
      });
    } 
  }

  majImage(){
    this.user.image = this.selectedFile.name
    this.userService.majImage(this.user._id,this.user).subscribe(data => {
});
  }

   containsImageExtension(url) {
    // Liste des extensions d'image courantes
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;

    // Teste si l'URL correspond à l'une des extensions d'image
    return imageExtensions.test(url);
}
onPasswordChange() {
    // Logique à exécuter lorsque le mot de passe est changé avec succès
    this.selectedTab = 'form1'; // Exemple de redirection vers un autre onglet
  }
}
