<app-header></app-header>
<div class="content-container d-flex flex-column min-vh-100">
  <div class="container-fluid pl-sm-5 pr-sm-5 bg-light">
    <div class="row">
      <div class="col-lg-12 mt-3">
        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a class="color-blue" routerLink="/"><i class="fa-solid fa-house"></i>
              Accueil</a>
          </li>
          <li class="breadcrumb-item active">Rendez-vous</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 col-md-6 col-sm-12">
        <h4>Mes rendez-vous</h4>
      </div>
    </div>
    <div class="card p-4">
      <div class="row mt-4">
        <div class="col-md-12 mb-3 mb-md-0">
          <div class="form-group">
            <div class="col row ">
              <ng-select [items]="medecins" bindLabel="medecinId.nom" placeholder="Médecin traitant"
                class="col form-control mr-2" [(ngModel)]="medecinToFiltred" (change)="filter()">
              </ng-select>
              <ng-select [items]="specialites" bindLabel="medecinId.specialite" placeholder="Spécialité"
                class="col form-control mr-2" [(ngModel)]="specialiteToFiltred" (change)="filter()">
              </ng-select>
              <ng-select [items]="rdvStatus" placeholder="Statut" class="col form-control mr-2" [closeOnSelect]="true"
                [(ngModel)]="StatusToFiltred" (change)="filter()">
              </ng-select>
              <button (click)="resetFilters()" class="btn btn-primary p-2 mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                  viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <style>
                    svg {
                      fill: #ffffff
                    }
                  </style>
                  <path
                    d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
  
      </div>
  
      <div class="row mt-4">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr class="ligne-paire text-center">
                  <th scope="col" style="color:#0090b2;">Date et heure</th>
                  <th scope="col" style="color:#0090b2;">Médecin traitant</th>
                  <th scope="col" style="color:#0090b2;">Spécialité</th>
                  <th scope="col" style="color:#0090b2;">Statut</th>
                  <th scope="col" style="color:#0090b2;">Actions</th>
                </tr>
              </thead>
              <tbody
                *ngFor="let appointment of rdvs | paginate: { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let k = index">
                <tr *ngIf="k%2 != 0" style="background-color: #e9f5f9; border: hidden;" class="text-center">
                  <td class="text-center">
                    <i class="fa-solid fa-calendar-days" style="color: #0090b2;"></i> {{appointment.start |
                    date:'dd/MM/yyyy'}}
                    <span style="margin-right: 10px;"></span>
                    <i class="fa-solid fa-clock" style="color: #0090b2;"></i> {{appointment.start | date:'HH:mm' }}
                  </td>
                  <td>{{appointment.medecinId.prenom}} {{appointment.medecinId.nom}}</td>
                  <td><span class="badge badge-custom">{{appointment.medecinId.specialite}}</span></td>
                  <td><span class="badge badge-appoitment" [ngClass]="{
                      'badge-annule': appointment.status === 'Annulé',
                      'badge-confirme': appointment.status === 'Confirmé',
                      'badge-non-confirme': appointment.status === 'En attente de confirmation'}">
                      {{appointment.status}}</span></td>
                  <td>
                    <div>
                      <a (click)="appointment.status !== 'Annulé' && !isAppointmentWithin24Hours(appointment.start) ? cancelAppointment(appointment._id) : null"
                        [ngClass]="{'disabled-link': appointment.status === 'Annulé' || isAppointmentWithin24Hours(appointment.start)}"
                        [attr.disabled]="appointment.status === 'Annulé' || isAppointmentWithin24Hours(appointment.start) ? true : null">
                        <i class="fa-solid fa-calendar-xmark mr-2" title="Annuler le rendez-vous"></i>
                      </a>
                      <a (click)="appointment.status !== 'Annulé' && !isAppointmentWithin24Hours(appointment.start) ? updateAppointment(appointment._id) : null"
                        [ngClass]="{'disabled-link': appointment.status === 'Annulé' || isAppointmentWithin24Hours(appointment.start)}"
                        [attr.disabled]="appointment.status === 'Annulé' || isAppointmentWithin24Hours(appointment.start) ? true : null">
                        <i class="fa-regular fa-pen-to-square mr-1" title="Modifier le rendez-vous"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;" class="text-center">
                  <td class="text-center">
                    <i class="fa-solid fa-calendar-days" style="color: #0090b2;"></i> {{appointment.start |
                    date:'dd/MM/yyyy'}}
                    <span style="margin-right: 10px;"></span>
                    <i class="fa-solid fa-clock" style="color: #0090b2;"></i> {{appointment.start | date:'HH:mm' }}
                  </td>
                  <td>{{appointment.medecinId.prenom}} {{appointment.medecinId.nom}}</td>
                  <td><span class="badge badge-custom">{{appointment.medecinId.specialite}}</span></td>
                  <td><span class="badge badge-appoitment" [ngClass]="{
                      'badge-annule': appointment.status === 'Annulé',
                      'badge-confirme': appointment.status === 'Confirmé',
                      'badge-non-confirme': appointment.status === 'En attente de confirmation'}">
                      {{appointment.status}}</span></td>
                  <td>
                    <div>
                      <a (click)="appointment.status !== 'Annulé' && !isAppointmentWithin24Hours(appointment.start) ? cancelAppointment(appointment._id) : null"
                        [ngClass]="{'disabled-link': appointment.status === 'Annulé' || isAppointmentWithin24Hours(appointment.start)}"
                        [attr.disabled]="appointment.status === 'Annulé' || isAppointmentWithin24Hours(appointment.start) ? true : null">
                        <i class="fa-solid fa-calendar-xmark mr-2" title="Annuler le rendez-vous"></i>
                      </a>
                      <a (click)="appointment.status !== 'Annulé' && !isAppointmentWithin24Hours(appointment.start) ? updateAppointment(appointment._id) : null"
                        [ngClass]="{'disabled-link': appointment.status === 'Annulé' || isAppointmentWithin24Hours(appointment.start)}"
                        [attr.disabled]="appointment.status === 'Annulé' || isAppointmentWithin24Hours(appointment.start) ? true : null">
                        <i class="fa-regular fa-pen-to-square mr-1" title="Modifier le rendez-vous"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="rdvs && rdvs.length > 8" class="border-0 mt-4">
              <div class="d-flex justify-content-center">
                <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant"
                  (pageChange)="onTableDataChange($event)">
                </pagination-controls>
              </div>
            </div>
            <div *ngIf="rdvs && rdvs.length == 0" class="table-responsive">
              <p class="text-center p-5 aucun">
                Aucun rendez-vous n'est trouvé
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>