import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SanteDetailsService } from 'src/app/_services/sante-details.service';

@Component({
  selector: 'app-popup-add-allergies',
  templateUrl: './popup-add-allergies.component.html',
  styleUrls: ['./popup-add-allergies.component.css']
})
export class PopupAddAllergiesComponent implements OnInit {
  
  algs
    allergiesList = [
      {
        _id :"667ebe53081ece2590a49eac",
        typeAllergie: "alimentaires",
        nature: [
          "Arachides",
          "Fruits de mer (crevettes, crabes, homards, etc.)",
          "Lait et produits laitiers",
          "Œufs",
          "Blé (gluten)",
          "Soja",
          "Noix (noix de cajou, amandes, noix de pécan, etc.)",
          "Poisson"
        ]
      },
      {
        _id :"667ebe53081ece2590a49ea8",
        typeAllergie: "respiratoires",
        nature: [
          "Pollen (arbres, graminées, herbes)",
          "Poussière (acariens)",
          "Moisissures",
          "Poils d'animaux (chats, chiens, chevaux, etc.)",
          "Plumes d'oiseaux"
        ]
      },
      {
        _id :"667ebe53081ece2590a49ea2",
        typeAllergie: "médicamenteuses",
        nature: [
          "Pénicilline et autres antibiotiques",
          "Aspirine et autres anti-inflammatoires non stéroïdiens (AINS)",
          "Anesthésiques locaux",
          "Sulfamides",
          "Médicaments anticonvulsivants"
        ]
      },
      {
        _id :"667ebe53081ece2590a49ea6",
        typeAllergie: "piqûres_d_insectes",
        nature: [
          "Abeilles",
          "Guêpes",
          "Frelons",
          "Fourmis rouges"
        ]
      },
      {
        _id :"667ebe53081ece2590a49eab",
        typeAllergie: "contact",
        nature: [
          "Latex",
          "Nickel (bijoux, montres, boucles de ceinture, etc.)",
          "Parfums et cosmétiques",
          "Produits chimiques (détergents, solvants, etc.)",
          "Plantes (lierre poison, chêne poison)"
        ]
      }
    ]
    selectedValues =  []
    typesAlergiesSelcted = []
    @Output() dossierMedicaleUpdated = new EventEmitter<any>();
    maladiesChoqs

  registerForm: any;
  submitted: boolean;
  vacs: any;
    constructor(private formBuilder: FormBuilder,private route :ActivatedRoute,private santeDetailService :SanteDetailsService,private router :Router,
      public iziToast: Ng2IzitoastService,
      public modal: NgbActiveModal
    ) { }
    ngOnInit(): void {
      this.registerForm = this.formBuilder.group({
        maladiesChroniques: [''],
        allergies: [''],
        vaccins : [''],
      });
  
    }
    get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
  
    if (this.registerForm.invalid) {
      return;
  }
  if(this.selectedValues.length > this.typesAlergiesSelcted.length){
    let differences = this.trouverDifferences(this.selectedValues, this.typesAlergiesSelcted);
  // Supprimer les éléments de liste1 qui sont différents de liste2
  differences.forEach(diff => {
      let index = this.selectedValues.findIndex(item => item.typeAllergie === diff.typeAllergie);
      if (index !== -1) {
        this.selectedValues.splice(index, 1);
      }
  });
  }
  this.registerForm.value.maladiesChroniques = this.maladiesChoqs
  this.registerForm.value.vaccins = this.vacs
  this.registerForm.value.allergies = this.selectedValues
  this.santeDetailService.ajoutInfoSante(this.registerForm.value).subscribe(
    (data) => {
      this.dossierMedicaleUpdated.emit(this.registerForm.value);
      this.modal.dismiss();
       })
     
     this.modal.dismiss();
}
createListAllergies(selectedItems){
 // this.typesAlergiesSelcted = selectedItems.map(item => item.typeAllergie);
 this.typesAlergiesSelcted = selectedItems
}

createListAllergiesWithNatures(typeAllergie,nature){
  const existingEntry = this.selectedValues.find(entry => entry.typeAllergie === typeAllergie);

    if (existingEntry) {
        // Mettre à jour les événements pour le type existant
        existingEntry.nature = nature;
    } else {
        // Ajouter une nouvelle entrée si le type n'existe pas encore
        this.selectedValues.push({ typeAllergie, nature });
    }
}

 trouverDifferences(liste1: any[], liste2: any[]): any[] {
  // Convertir liste2 en ensemble de types d'allergie pour une recherche efficace
  const typesListe2 = new Set(liste2.map(item => item.typeAllergie));

  // Filtrer les éléments de liste1 qui ne sont pas présents dans liste2
  return liste1.filter(item => !typesListe2.has(item.typeAllergie));
}

cancel(){
  this.modal.dismiss();
}
}
