import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SanteDetailsService } from 'src/app/_services/sante-details.service';

@Component({
  selector: 'app-popup-delete-allergie',
  templateUrl: './popup-delete-allergie.component.html',
  styleUrls: ['./popup-delete-allergie.component.css']
})
export class PopupDeleteAllergieComponent implements OnInit {
  algs
  alergieToDeleted
  registerForm: any;
  maladiesChoqs: any;
  @Output() dossierMedicaleUpdated = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,private route :ActivatedRoute,private santeDetailService :SanteDetailsService,private router :Router,
    public iziToast: Ng2IzitoastService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      maladiesChroniques: [''],
      allergies: [''],
    });

  }
    onSubmit() {
    this.algs = this.algs.filter(allergy => allergy.typeAllergie !== this.alergieToDeleted);
    this.registerForm.value.allergies = this.algs
    this.registerForm.value.maladiesChroniques = this.maladiesChoqs
    this.santeDetailService.ajoutInfoSante(this.registerForm.value).subscribe(
      (data) => {
        this.dossierMedicaleUpdated.emit(this.registerForm.value);
        this.modal.dismiss();
        })
      
      this.modal.dismiss();
    }

    cancel(){
      this.modal.dismiss();
    }
  
  
}
