import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-rdv',
  templateUrl: './update-rdv.component.html',
  styleUrls: ['./update-rdv.component.css']
})
export class UpdateRdvComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
