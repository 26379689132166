import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SanteDetailsService } from 'src/app/_services/sante-details.service';
import { PopupAddAllergiesComponent } from 'src/app/popus/dossierMedical/popup-add-allergies/popup-add-allergies.component';
import { PopupAddMaladieChroniqueComponent } from 'src/app/popus/dossierMedical/popup-add-maladie-chronique/popup-add-maladie-chronique.component';
import { PopupAddMedicamentComponent } from 'src/app/popus/dossierMedical/popup-add-medicament/popup-add-medicament.component';
import { PopupAddVaccinationsComponent } from 'src/app/popus/dossierMedical/popup-add-vaccinations/popup-add-vaccinations.component';
import { PopupDeleteAllergieComponent } from 'src/app/popus/dossierMedical/popup-delete-allergie/popup-delete-allergie.component';
import { PopupEditNatureAllergieComponent } from 'src/app/popus/dossierMedical/popup-edit-nature-allergie/popup-edit-nature-allergie.component';

@Component({
  selector: 'app-health-details-form',
  templateUrl: './health-details-form.component.html',
  styleUrls: ['./health-details-form.component.css']
})
export class HealthDetailsFormComponent implements OnInit {
  @Input() user: any;
  santeDetails
  maladiesChroniques
  allergies :any
  vaccins: any[];
  medicaments :any

  sizeContent: any;


  constructor(private santeDetailsService :SanteDetailsService,private modalService: NgbModal) { }

  ngOnInit(): void {
 
  this.santeDetailsService.getInfoSante().subscribe(data => {
    this.santeDetails = data
    this.maladiesChroniques = data.maladiesChroniques
    this.allergies = data.allergies
    this.vaccins = data.vaccins
    this.medicaments = data.medicaments
    this.sizeContent=this.vaccins.length
})
  }

  cardStates: boolean[] = [false, false, false]; 

  toggleCard(index: number) {
    this.cardStates[index] = !this.cardStates[index];
  }
  addMaladieChronoique() {
    const modalRef = this.modalService.open(PopupAddMaladieChroniqueComponent);
    modalRef.componentInstance.maladiesChoqs = this.maladiesChroniques;
    modalRef.componentInstance.algs = this.allergies;
    modalRef.componentInstance.vacs = this.vaccins;
    modalRef.componentInstance.dossierMedicaleUpdated.subscribe((data) => {
      this.maladiesChroniques = data.maladiesChroniques;
    });
  
  }
  addAllergies() {
    const modalRef = this.modalService.open(PopupAddAllergiesComponent);
    modalRef.componentInstance.maladiesChoqs = this.maladiesChroniques;
    modalRef.componentInstance.algs = this.allergies;
    modalRef.componentInstance.vacs = this.vaccins;
    modalRef.componentInstance.dossierMedicaleUpdated.subscribe((data) => {
      this.allergies = data.allergies;
    });
  }
  addVaccinations() {
    const modalRef = this.modalService.open(PopupAddVaccinationsComponent);
    modalRef.componentInstance.maladiesChoqs = this.maladiesChroniques;
    modalRef.componentInstance.algs = this.allergies;
    modalRef.componentInstance.vacs = this.vaccins;
    modalRef.componentInstance.dossierMedicaleUpdated.subscribe((data) => {
      this.vaccins = data.vaccins;
    });
  }
  addMedicament() {
    this.modalService.open(PopupAddMedicamentComponent);
  }

  deleteAllergie(allergie){
    const modalRef = this.modalService.open(PopupDeleteAllergieComponent);
    modalRef.componentInstance.maladiesChoqs = this.maladiesChroniques;
    modalRef.componentInstance.algs = this.allergies;
    modalRef.componentInstance.alergieToDeleted = allergie;
    modalRef.componentInstance.dossierMedicaleUpdated.subscribe((data) => {
      this.allergies = data.allergies;
    });

  }

  editNatureAllergie(type,natures){
    const modalRef = this.modalService.open(PopupEditNatureAllergieComponent);
    modalRef.componentInstance.allergieType = type
    modalRef.componentInstance.allergieNatures = natures
    modalRef.componentInstance.maladiesChoqs = this.maladiesChroniques;
    modalRef.componentInstance.santeDetails = this.santeDetails
  }
}
