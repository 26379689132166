import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SanteDetailsService } from 'src/app/_services/sante-details.service';

@Component({
  selector: 'app-popup-edit-nature-allergie',
  templateUrl: './popup-edit-nature-allergie.component.html',
  styleUrls: ['./popup-edit-nature-allergie.component.css']
})
export class PopupEditNatureAllergieComponent implements OnInit {

  allergiesList = [
    {
      _id :"667ebe53081ece2590a49eac",
      typeAllergie: "alimentaires",
      nature: [
        "Arachides",
        "Fruits de mer (crevettes, crabes, homards, etc.)",
        "Lait et produits laitiers",
        "Œufs",
        "Blé (gluten)",
        "Soja",
        "Noix (noix de cajou, amandes, noix de pécan, etc.)",
        "Poisson"
      ]
    },
    {
      _id :"667ebe53081ece2590a49ea8",
      typeAllergie: "respiratoires",
      nature: [
        "Pollen (arbres, graminées, herbes)",
        "Poussière (acariens)",
        "Moisissures",
        "Poils d'animaux (chats, chiens, chevaux, etc.)",
        "Plumes d'oiseaux"
      ]
    },
    {
      _id :"667ebe53081ece2590a49ea2",
      typeAllergie: "médicamenteuses",
      nature: [
        "Pénicilline et autres antibiotiques",
        "Aspirine et autres anti-inflammatoires non stéroïdiens (AINS)",
        "Anesthésiques locaux",
        "Sulfamides",
        "Médicaments anticonvulsivants"
      ]
    },
    {
      _id :"667ebe53081ece2590a49ea6",
      typeAllergie: "piqûres_d_insectes",
      nature: [
        "Abeilles",
        "Guêpes",
        "Frelons",
        "Fourmis rouges"
      ]
    },
    {
      _id :"667ebe53081ece2590a49eab",
      typeAllergie: "contact",
      nature: [
        "Latex",
        "Nickel (bijoux, montres, boucles de ceinture, etc.)",
        "Parfums et cosmétiques",
        "Produits chimiques (détergents, solvants, etc.)",
        "Plantes (lierre poison, chêne poison)"
      ]
    }
  ]
  test=["Aspirine et autres anti-inflammatoires non stéroïdiens (AINS)"]

  allergieType
  allergieNatures 
  naturesAllergieChoised : any
  santeDetails :any

    constructor(private santeDetailService :SanteDetailsService,
      public iziToast: Ng2IzitoastService,
      public modal: NgbActiveModal
    ) { }
    ngOnInit(): void {
      let allergieChoised = this.allergiesList.find(x=>x.typeAllergie === this.allergieType)
      this.naturesAllergieChoised = allergieChoised.nature
    }

    onSubmit() {
      let allergieUpdateNature = this.santeDetails.allergies.find(x => x.typeAllergie === this.allergieType)
      allergieUpdateNature.nature = this.allergieNatures
    this.santeDetailService.ajoutInfoSante(this.santeDetails).subscribe(
      (data) => {
        this.modal.dismiss();
         })
       
       this.modal.dismiss();
  }

  cancel(){
    this.modal.dismiss();
  }

}
