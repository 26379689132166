import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SanteDetailsService } from 'src/app/_services/sante-details.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-popup-add-maladie-chronique',
  templateUrl: './popup-add-maladie-chronique.component.html',
  styleUrls: ['./popup-add-maladie-chronique.component.css']
})
export class PopupAddMaladieChroniqueComponent implements OnInit {
  maladies = [  "Diabète",
    "Maladies cardiovasculaires",
    "Asthme",
    "Arthrite",
    "Maladies pulmonaires obstructives chroniques (MPOC)",
    "Cancer",
    "Maladies rénales chroniques",
    "Hépatite chronique",
    "Sclérose en plaques",
    "Insuffisance cardiaque congestive",
    "Maladie d'Alzheimer et autres démences",
    "Maladies inflammatoires de l'intestin (MII)",
    "Obésité",
    "Ostéoporose",
    "Syndrome métabolique",
    "Maladies auto-immunes"]
    maladiesChoqs :any // la liste des maldies chroniques d'un patient qui ont dans la base  
    algs :any
  registerForm: any;
  submitted: boolean =false ;
  @Output() dossierMedicaleUpdated = new EventEmitter<any>();
  vacs: any;

  constructor(private formBuilder: FormBuilder,private route :ActivatedRoute,private santeDetailService :SanteDetailsService,private router :Router,
    public iziToast: Ng2IzitoastService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      maladiesChroniques: [''],
      allergies: [''],
      vaccins : [''],
    });

  }
  get f() { return this.registerForm.controls; }
onSubmit() {
  this.submitted = true;

  if (this.registerForm.invalid) {
    return;
}
this.registerForm.value.allergies = this.algs
this.registerForm.value.vaccins = this.vacs

this.santeDetailService.ajoutInfoSante(this.registerForm.value).subscribe(
(data) => {
  this.dossierMedicaleUpdated.emit(this.registerForm.value);
  this.modal.dismiss();
   })
 
 this.modal.dismiss();
}

onReset() {
  this.modal.dismiss();

}
cancel(){
  this.modal.dismiss();
}
}
