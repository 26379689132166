import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MedecinPatientService } from 'src/app/_services/medecin-patient.service';
import { UserService } from 'src/app/_services/user.service';
import { PopupAddAppointmentComponent } from 'src/app/popus/rendez-vous/popup-add-appointment/popup-add-appointment.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  user: any;
  imc  : any;
  medecinsTraitants :any
  sizeContent: any;
  constructor(private userService: UserService ,
    private medecinPatientService :MedecinPatientService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.userService.getUser().subscribe(user => {
      this.user = user;
      let poids = parseFloat(this.user.poids);
      let taille = parseFloat(this.user.taille);
      this.imc = poids / ((taille / 100) ** 2);
      this.imc =this.imc.toFixed(2);
    });
    this.getMedecinsTraitants()
  }

  getMedecinsTraitants(){
    this.medecinPatientService.getMedecinsTraitants().subscribe(data => {
    this.medecinsTraitants = data
    this.sizeContent=this.medecinsTraitants.length
  })
  }
  addAppointment(medecin){
    const modalRef = this.modalService.open(PopupAddAppointmentComponent);
    modalRef.componentInstance.medecin = medecin;
  }


}


