<div class="container d-flex justify-content-center align-items-center vh-100">
    <div class="col-lg-12">
        <div class="card p-4">
            <h4 *ngIf="user" class="text-center">Bienvenu {{user.prenom}} {{user.nom}} à Medivisto</h4>
            <h5 class="text-center" style="font-style: italic;">Merci de compléter votre inscription.</h5>
                <form class="mt-3" #f0="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    
                    <div class="form-row">
                        <div class="col-4">
                            <div class="form-group">
                            <label>Sexe<b style="color: red"> *</b></label>
                            <ng-select [items]="Sexes"
                            class="form-control "
                            [closeOnSelect]="true"
                            formControlName="sexe"
                            [ngClass]="{ 'is-invalid': submitted && f.sexe.errors }"
                            >
                           </ng-select>      
                           <div *ngIf="submitted && f.sexe.errors" class="invalid-feedback">
                            <div *ngIf="f.sexe.errors.required">Ce champ est obligatoire.</div>
                          </div>
                         </div>
                      </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label>Profession<b style="color: red"> *</b></label>
                                <input type="text" class="form-control" formControlName="profession" 
                                onkeypress="return /[a-z A-Z]/.test(event.key)"
                                [ngClass]="{'is-invalid': submitted && f.profession.errors}">
                                <div *ngIf="submitted && f.profession.errors"
                                class="invalid-feedback" >       
                                <div *ngIf="f.profession.errors.required">
                                Ce champ est obligatoire.
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label>Statut familial<b style="color: red"> *</b></label>
                                <ng-select [items]="statusFamiliales"
                                class="form-control "
                                [closeOnSelect]="true"
                                formControlName="statusFamilial"
                                [ngClass]="{ 'is-invalid': submitted && f.statusFamilial.errors }"
                                >
                           </ng-select>
                                <div
                                *ngIf="submitted && f.statusFamilial.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.statusFamilial.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-6">
                            <div class="form-group">
                                <label>Date de naisssance<b style="color: red"> *</b></label>
                                <input type="date" class="form-control" formControlName="dateAnniversaire" 
                                onkeypress="return /[0-9]/.test(event.key)"
                                [ngClass]="{'is-invalid': submitted && f.dateAnniversaire.errors}">
                                <div
                                *ngIf="submitted && f.dateAnniversaire.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.dateAnniversaire.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                <div *ngIf="f.dateAnniversaire.errors.tooYoung">
                                  L'âge doit être au moins de 12 ans.
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Type d'organisme<b style="color: red"> *</b></label>
                                <ng-select [items]="typeOrgs"
                                formControlName="typeOrg"
                                class="form-control input-custom"
                                [closeOnSelect]="true"
                                [ngClass]="{ 'is-invalid': submitted && f.typeOrg.errors }"
                                >
                                </ng-select>
                                <div
                                *ngIf="submitted && f.typeOrg.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.typeOrg.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                </div>
                            </div>
                        </div>
                
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label>Taille(cm)<b style="color: red"> *</b></label>
                                <input type="text" class="form-control"  formControlName="taille" 
                                onkeypress="return /[0-9]/.test(event.key)"
                                [ngClass]="{ 'is-invalid': submitted && f.taille.errors }"
                                >
                                <div
                                *ngIf="submitted && f.taille.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.taille.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                <div *ngIf="f.taille.errors.tailleInvalid">
                                  La taille doit être comprise entre 50 cm et 230 cm.
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Poids(kg)<b style="color: red"> *</b></label>
                                <input type="text" class="form-control" formControlName="poids" 
                                onkeypress="return /[0-9]/.test(event.key)" 
                                [ngClass]="{ 'is-invalid': submitted && f.poids.errors }"
                                >
                                <div
                                *ngIf="submitted && f.poids.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.poids.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                <div *ngIf="f.taille.errors.tailleInvalid">
                                  Le taille doit être comprise entre 20 kg et 300 kg.
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Groupe Sanguin<b style="color: red"> *</b></label>
                                <ng-select [items]="groupeSanguins"
                                class="form-control "
                                [closeOnSelect]="true"
                                formControlName="groupeSanguin"
                                [ngClass]="{ 'is-invalid': submitted && f.groupeSanguin.errors }"
                                >
                           </ng-select>
                            <div
                            *ngIf="submitted && f.groupeSanguin.errors"
                            class="invalid-feedback"
                            >
                            <div *ngIf="f.groupeSanguin.errors.required">
                                Ce champ est obligatoire.
                            </div>
                            </div>
                                </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="firstname"
                            >Mot de passe <b style="color: red">*</b></label
                          >
                          <input
                            type="password"
                            id="password"
                            name="password"
                            class="form-control"
                            formControlName="password"
                            autocomplete="new-password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                          />
                          <div
                            *ngIf="submitted && f.password.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="f.password.errors.required">
                              Ce champ est obligatoire.
                            </div>
                            <div *ngIf="f.password.errors.maxlength">
                              Le mot de passe doit contenir au maximum 50 caractères.
                            </div>
                            <div *ngIf="f.password.errors.pattern">
                              Le mot de passe doit comporter au moins 6 caractères, incluant au moins une lettre minuscule, 
                              une lettre majuscule, un chiffre et un caractère spécial.
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="lastname"
                            >Confirmation du mot de passe <b style="color: red">*</b></label
                          >
                          <input
                            type="password"
                            id="password"
                            name="password"
                            class="form-control"
                            formControlName="confirmationPassword"
                            [ngClass]="{
                              'is-invalid': submitted && f.confirmationPassword.errors
                            }"
                          />
                          <div
                            *ngIf="submitted && f.confirmationPassword.errors"
                            class="invalid-feedback"
                          >
                            <div *ngIf="f.confirmationPassword.errors.required">
                              Ce champ est obligatoire.
                            </div>
                            <div *ngIf="f.confirmationPassword.errors.maxlength">
                              Le mot de passe doit contenir au maximum 50 caractères.
                            </div>
                            <div *ngIf="f.confirmationPassword.errors.pattern">
                              Le mot de passe doit comporter au moins 6 caractères, incluant au moins une lettre minuscule,
                               une lettre majuscule, un chiffre et un caractère spécial.
                            </div>
                            <div *ngIf="f.confirmationPassword.errors.mustMatch">
                              Les mots de passe ne correspondant pas.
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="form-group text-center mt-2">
                        <button type="submit" class="btn btn-secondary mr-3">Annuler</button>
                        <button type="submit" class="btn btn-primary">Enregistrer</button>
                    </div>
                </form>
        </div>
    </div>
    </div>