import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../_models/user';
import { TokenStorageService } from './token-storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  users :any []=[];
  //private _authenticationURL="http://localhost:3200/api/authentication"
  private _authenticationURL=`${environment.baseUrl}/authenticate` 
  //currentUser:Observable<User>;
  
  constructor(private http: HttpClient,private tokenStorageService :TokenStorageService,private router :Router ) {
    // this.currentUserSubject = new BehaviorSubject<User>(
    //   JSON.parse(localStorage.getItem('currentUser'))
    // );
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }
loginUser(user)
{
  return this.http.post(`${this._authenticationURL}`,user, {responseType: 'json'});
}


getToken()
{
  return localStorage.getItem('token')
}

loggedIn()
{
  return !!localStorage.getItem('token')
}


logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  this.tokenStorageService.removeUser();
  this.router.navigate(['/connexion'])

  
}

}
