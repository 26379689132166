import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SanteDetailsService {

  private _detailsSanteURL=`${environment.baseUrl}/santeDetails`
  constructor(private http: HttpClient) { }
 
  // patient add a other file
     
      ajoutInfoSante(detailsSante: any):Observable<Object> {
         return this.http.put(`${this._detailsSanteURL}/ajout/info-sante`,detailsSante);
       }
 
       getInfoSante():Observable<any>
       {
         return this.http.get<any>(`${this._detailsSanteURL}/byPatientCourant`);
       } 

}
