import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.css']
})
export class PasswordFormComponent implements OnInit {
  @Input() user: any;
  @Output() passwordChange = new EventEmitter<void>();
  registerForm: FormGroup;
  submitted = false;

  constructor(private userService: UserService,
    private router: Router, 
    private formBuilder: FormBuilder,
    public iziToast: Ng2IzitoastService,
  ) {}

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')
      ]],
      newpassword: ['', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')
      ]],
      confirmpassword: ['', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')
      ]],
      email :['']
    },
    {
      validator: MustMatch('newpassword', 'confirmpassword')
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }

    this.registerForm.value.email = this.user.email
    this.userService.updatePassowrd(this.registerForm.value).subscribe(
        (data) => {
          if (data["message"] === "Mot de passe actuel invalide" || data["message"] === "Le nouveau mot de passe doit être différent de l'ancien mot de passe") {
              this.iziToast.show({
                message: data["message"],
                messageColor: '#FF0000',
                progressBarColor: '#FF6347',
                icon: 'bi-exclamation-circle',
                imageWidth: 45,
                position: 'topRight',
                timeout: 5000,
                backgroundColor: '#f8d7da',
                transitionIn: 'flipInX',
                transitionOut: 'flipOutX',
              });
              return;
          } 
          this.iziToast.show({
              message: 'Mise à jour de mot de passe avec succès',
              messageColor: '#386641',
              progressBarColor: '#6a994e',
              icon: 'bi-check-circle',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#dde5b6',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
          });
          this.passwordChange.emit(); // Emit the event
          this.router.navigate(['/patient/profil']);
        },
        (error) => {
        }
    );
  }
}
