<div class="modal-body">
  <!-- Informations sur le médecin -->
  <div class="d-flex align-items-center">
    <div class="icon-background">
      <i class="fa-solid fa-user-doctor icone"></i>
    </div>
    <div class="ml-3">
      <h5>Dr {{medecin.prenom}} {{medecin.nom}}</h5>
      <span class="badge bg-color">{{medecin.specialite}}</span>
    </div>
  </div>

  <!-- Sélection de la date -->
  <h6 class="choose mt-3">Veuillez choisir la date du rendez-vous</h6>
  <div class="date-header mt-2">
    <span class="arrow" (click)="previousMonth()">&lt;</span>
    <span class="badge badge-primary">{{ calendarDays[0]?.monthName }} - {{ currentYear }}</span>
    <span class="arrow" (click)="nextMonth()">&gt;</span>
  </div>

  <div class="calendar mb-3">
    <span class="arrow" (click)="previousWeek()">&lt;</span>
    <div *ngFor="let day of calendarDays" [class.active]="day.active" [class.disabled]="day.isPast || !day.isAvailable"
      class="calendar-day ml-2 p-2" (click)="selectDay(day)">
      <span class="day-name">{{ day.dayName }}</span>
      <span class="day-number">{{ day.dayNumber }}</span>
    </div>
    <span class="arrow" (click)="nextWeek()">&gt;</span>
  </div>

  <!-- Sélection de l'heure (affichée après la sélection de la date) -->
  <div>
    <h6 class="choose">Veuillez choisir l'heure du rendez-vous</h6>
    <div class="time-slots mb-3">
      <div *ngIf="timeSlots.length === 0" class="card p-3">
        <i class="fa-solid fa-house-medical-circle-xmark icone-style mb-2"></i>
        <span class="aucun">Aucun créneau n'est disponible pour cette date.</span>
      </div>
      <div *ngFor="let slot of timeSlots" [class.active]="slot.active" class="time-slot ml-2 p-3 mb-2"
        (click)="selectTimeSlot(slot)">
        {{ slot.time }}
      </div>
    </div>
  </div>
  <div *ngIf="timeSelected">
    <h6 class="choose">Veuillez choisir le motif de consultation</h6>
    <ng-select [items]="Motifs" [(ngModel)]="selectedMotif" placeholder="Sélectionnez un motif" class="form-control" [closeOnSelect]="true">
    </ng-select>    
  </div>

  <!-- Boutons Annuler et Enregistrer -->
  <div *ngIf="timeSlots.length > 0" class="form-group text-center mt-2">
    <button (click)="cancel()" type="reset" class="btn btn-secondary mr-3">Annuler</button>
    <button (click)="updateRdv()" type="submit" class="btn btn-primary">Enregistrer</button>
  </div>
</div>