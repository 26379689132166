import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class ForgetPassowrdService {
  private _forgetPassword=`${environment.baseUrl}/forget-password` 
  constructor(private http: HttpClient) { }

  sendMail(email):Observable<Object> {
    return this.http.post(`${this._forgetPassword}`,email);
  }
  
  updatePassword(token:string,user:User):Observable<Object> {
    return this.http.put(`${this._forgetPassword}/forgetPassowrd/${token}`,user);
  }

  verifCodeSended(token:string,user):Observable<Object> {
    return this.http.post(`${this._forgetPassword}/verifCodeSended/${token}`,user);
  }
}
