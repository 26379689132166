import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { EventService } from 'src/app/_services/event.service';
import { RdvService } from 'src/app/_services/rdv.service';

@Component({
  selector: 'app-popup-cancel-appointment',
  templateUrl: './popup-cancel-appointment.component.html',
  styleUrls: ['./popup-cancel-appointment.component.css']
})
export class PopupCancelAppointmentComponent implements OnInit {
  @Input() rdvId!: string;
  @Output() rdvCancel = new EventEmitter<void>();
  rdvDetails: any;
  event: any;

  constructor(public modal: NgbActiveModal,
    private rdvService: RdvService,
    private eventService: EventService,
    public iziToast: Ng2IzitoastService,
  ) { }

  ngOnInit(): void {
    this.getRdvDetails(); 
  }
  cancel(){
    this.modal.dismiss();
  }
  getRdvDetails() {
    this.rdvService.getRdvById(this.rdvId).subscribe(
      (data) => {
        this.rdvDetails = data;
        const formattedDateTime = this.formatDateTime(this.rdvDetails.start);
        this.eventService.getEventsByStart(formattedDateTime).subscribe(data=>{
          this.event=data       
        })
      },
      (error) => {
        console.error('Erreur lors de la récupération des détails du rendez-vous:', error);
      }
    );
  }
  

  confirmCancel(): void {
    this.rdvService.cancelRdv(this.rdvId).subscribe(
      (data) =>{
        this.iziToast.show({
          message: 'Rendez-vous annulé avec succés',
          messageColor: '#386641',
          progressBarColor: '#6a994e',
          icon: 'bi-check-circle',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
      });
      this.rdvCancel.emit();
      this.modal.close('Rendez-vous annulé');
      }
    );
    this.eventService.toggleLibre(this.event._id).subscribe(data=>{
      console.log(data)
    })
  }
  formatDateTime(dateTime: string): string {
    // Split the datetime string into date and time parts
    const [date, time] = dateTime.split('T');
    
    // Keep the hours and minutes from the time part
    const [hours, minutes] = time.split(':');
    
    // Combine the date part with the hours and minutes
    return `${date}T${hours}:${minutes}`;
  }
}
