import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/_services/shared.service';
import { PopupUpdateProfilPatientComponent } from 'src/app/popus/popup-update-profil-patient/popup-update-profil-patient.component';

@Component({
  selector: 'app-general-info-form',
  templateUrl: './general-info-form.component.html',
  styleUrls: ['./general-info-form.component.css']
})
export class GeneralInfoFormComponent implements OnInit, OnChanges {
  @Input() user: any;
  age: any;
  test = "1323";

  constructor(private modalService: NgbModal, private sharedService: SharedService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user'] && this.user) {
      this.age = this.calculateAge(this.user.dateAnniversaire);
    }
  }

  calculateAge(birthDate) {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }

    return age;
  }

  updateProfil() {
    const modalRef = this.modalService.open(PopupUpdateProfilPatientComponent);
    modalRef.componentInstance.patient = this.user;

    modalRef.componentInstance.profileUpdated.subscribe((updatedProfile) => {
      this.user.taille = updatedProfile.taille;
      this.user.poids = updatedProfile.poids;
      this.user.dateAnniversaire = updatedProfile.dateAnniversaire;
      this.calculateIMC();
      this.age = this.calculateAge(this.user.dateAnniversaire);
      this.user.tel = updatedProfile.tel;
    });
  }

  calculateIMC() {
    if (this.user.taille && this.user.poids) {
      const tailleEnMetres = this.user.taille / 100;
      const imc = this.user.poids / (tailleEnMetres * tailleEnMetres);
      this.sharedService.updateImc(imc);
    }
  }
}
