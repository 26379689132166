<div class="modal-header">
    <h5 class="modal-title">Dossier médicale - maladies chroniques</h5>
      <button
      type="button"
      class="btn-close exit-button"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ><i class="fa-regular fa-circle-xmark"></i>
    </button>
  </div>
       
  <div class="modal-body">
    <div>
     <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
     <div class="form-row">
            <div class="col">
                <div class="form-group">
                    <label>Maladies Chroniques:</label>
                    <ng-select [items]="maladies"
                    class="form-control "
                    [closeOnSelect]="true"
                    formControlName="maladiesChroniques"
                    [(ngModel)]="maladiesChoqs"
                    multiple = true 
                    >
                   </ng-select>      
                </div>
            </div>        
        </div>
         
                    <div class="form-group text-center mt-2">
                        <button (click)="cancel()" type="reset" class="btn btn-secondary mr-3">Annuler</button>
                        <button type="submit" class="btn btn-primary">Enregistrer</button>
                    </div>
    </form>

</div>
</div>
       
