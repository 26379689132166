import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-add-medicament',
  templateUrl: './popup-add-medicament.component.html',
  styleUrls: ['./popup-add-medicament.component.css']
})
export class PopupAddMedicamentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
