import { HttpInterceptor ,HttpErrorResponse } from '@angular/common/http';
import { Injectable,Injector } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector :Injector,private router :Router,private authenticationService :AuthenticationService,public iziToast: Ng2IzitoastService) { }

  intercept(req ,next ) {
  let authenticationService = this.injector.get(AuthenticationService)
  let tokenzedReq =req.clone({
    setHeaders: {
      Authorization : `Bearer ${authenticationService.getToken()}`
    }
  })
  //

  //////////////////////////////////////* *///// alert red for token exipred or ivalid 
  return next.handle(tokenzedReq).pipe(
    catchError((error: HttpErrorResponse) => {
       if(error.error.text === "Token expiré" || error.error.text === "Token invalide"){
        // setTimeout(() => {
        //   this.iziToast.show({
        //     message: "Votre accès a été expiré. Veuillez vous reconnecter pour continuer.",
        //     messageColor: '#800f2f',
        //     titleColor: '#800f2f',
        //     progressBarColor: '#c9184a',
        //     icon: 'bi-exclamation-diamond',
        //     imageWidth: 45,
        //     position: 'topRight',
        //     timeout: 5000,
        //     backgroundColor: '#ff8fa3',
        //     transitionIn: 'flipInX',
        //     transitionOut: 'flipOutX',
        //     overlay: true,
        //     overlayClose: true,
        //   });
        // }, 3000); // 3000 milliseconds (3 seconds)
        localStorage.clear()
        this.authenticationService.logout()
        //window.location.reload();
       }
      //  else
      //  {
      //   this.iziToast.show({
      //     message: "Une erreur est survenue.",
      //     messageColor: '#800f2f',
      //     titleColor: '#800f2f',
      //     progressBarColor: '#c9184a',
      //     icon: 'bi-exclamation-diamond',
      //     imageWidth: 45,
      //     position: 'topRight',
      //     timeout: 5000,
      //     backgroundColor: '#ff8fa3',
      //     transitionIn: 'flipInX',
      //     transitionOut: 'flipOutX',
      //     overlay: true,
      //     overlayClose: true,
      //   });
      //  }

       return throwError(0);
    })
)


// update for evite alert red exipred
//return next.handle(tokenzedReq)


  }
 
}
 
