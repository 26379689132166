import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RdvService } from 'src/app/_services/rdv.service';
import { PopupCancelAppointmentComponent } from 'src/app/popus/rendez-vous/popup-cancel-appointment/popup-cancel-appointment.component';
import { PopupUpdateAppointmentComponent } from 'src/app/popus/rendez-vous/popup-update-appointment/popup-update-appointment.component';

@Component({
  selector: 'app-rdvs',
  templateUrl: './rdvs.component.html',
  styleUrls: ['./rdvs.component.css']
})
export class RdvsComponent implements OnInit {
  rdvs: any;
  copyListRdv: any;
  medecinToFiltred: any
  specialiteToFiltred: any
  StatusToFiltred: any
  page: number = 1;
  tableSize: number = 8;
  tableSizes: any = [5, 10, 15, 20];
  count: number = 0;
  rdvStatus = [
    "Disponible",
    "En attente de confirmation",
    "Confirmé",
    "Annulé",
    "Reporté",
];
  medecins: any
  specialites: any
  constructor(private modalService: NgbModal,
  private rdvService: RdvService,
  ) { }

  ngOnInit(): void {
    this.getRdvs()
  }
  onTableDataChange(event: any) {
    this.page = event;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }
  getRdvs(): void {
    this.rdvService.getAllRdvByCurrentPatient().subscribe(data => {
      this.rdvs = data;
      this.copyListRdv = this.rdvs;
      this.extractMedecins();
      this.extractSpecialite();
    });
  }
  extractMedecins(): void {
    this.medecins = [...new Set(this.rdvs.map(rdv => `${rdv.medecinId.prenom} ${rdv.medecinId.nom}`))];
  }
  extractSpecialite():void{
    this.specialites = [...new Set(this.rdvs.map(rdv => `${rdv.medecinId.specialite}`))]
  }
  cancelAppointment(rdvId: string){
    const modalRef = this.modalService.open(PopupCancelAppointmentComponent);
    modalRef.componentInstance.rdvId = rdvId;
    modalRef.componentInstance.rdvCancel.subscribe(() => {
      this.getRdvs(); 
    });
  }
  updateAppointment(rdvId: string){
    const modalRef = this.modalService.open(PopupUpdateAppointmentComponent);
    modalRef.componentInstance.rdvId = rdvId;
    modalRef.componentInstance.rdvupdate.subscribe(() => {
      this.getRdvs(); 
    });
  }
  filter() {
    this.rdvs = this.copyListRdv;
    let filteredRdvs = [...this.copyListRdv];
    const medecinFilterLower = this.medecinToFiltred ? this.medecinToFiltred.toLowerCase() : '';
    const specialiteFilterLower = this.specialiteToFiltred ? this.specialiteToFiltred.toLowerCase() : '';
    const statusFilterLower = this.StatusToFiltred ? this.StatusToFiltred.toLowerCase() : '';
    filteredRdvs = this.rdvs.filter(item => {
      const medecinFilter = medecinFilterLower ?  `${item.medecinId.prenom} ${item.medecinId.nom}`.toLowerCase().includes(medecinFilterLower) : true;
      const specialiteFilter = specialiteFilterLower ? `${item.medecinId.specialite} `.toLowerCase().includes(specialiteFilterLower) : true;
      const statusFilter = statusFilterLower ? item.status.toLowerCase().includes(statusFilterLower) : true;
      return   medecinFilter && specialiteFilter && statusFilter
    });
     this.rdvs = filteredRdvs;
  
     // Reset pagination
     this.page = 1;
  }
  resetFilters() {
    this.medecinToFiltred = undefined; 
    this.specialiteToFiltred = undefined; 
    this.StatusToFiltred = undefined; 
   this.filter();
  }
  isAppointmentWithin24Hours(appointmentDate: Date): boolean {
    const now = new Date();
    const appointmentDateTime = new Date(appointmentDate);
    const timeDifference = appointmentDateTime.getTime() - now.getTime();
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference <= 24;
  }
}
