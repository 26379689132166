<div *ngIf="user">   
    <form #f00="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="col-6">
            <div class="form-group">
                <label>Ancien Mot de passe:</label>
                <input  type="password" class="form-control" formControlName="password"  autocomplete="new-password"

                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" >
                <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Ce champ est obligatoire.
                </div>
                <div *ngIf="f.password.errors.maxlength">
                  Le mot de passe doit contenir au maximum 50 caractères.
                </div>
                <div *ngIf="f.password.errors.pattern">
                  Le mot de passe doit comporter au moins 6 caractères, incluant au moins une lettre minuscule, 
                  une lettre majuscule, un chiffre et un caractère spécial.
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-6">
            <div class="form-group">
                <label>Nouveau Mot de passe:</label>
                <input  type="password" class="form-control" formControlName="newpassword" autocomplete="new-password"

                [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }" >
                <div
                *ngIf="submitted && f.newpassword.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.newpassword.errors.required">
                  Ce champ est obligatoire.
                </div>
                <div *ngIf="f.newpassword.errors.maxlength">
                  Le mot de passe doit contenir au maximum 50 caractères.
                </div>
                <div *ngIf="f.newpassword.errors.pattern">
                  Le mot de passe doit comporter au moins 6 caractères, incluant au moins une lettre minuscule, 
                  une lettre majuscule, un chiffre et un caractère spécial.
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-6">
            <div class="form-group">
                <label>Confirmation du mot de passe:</label>
                <input  type="password" class="form-control" formControlName="confirmpassword" autocomplete="new-password"

                [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" >
                <div
                *ngIf="submitted && f.confirmpassword.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.confirmpassword.errors.required">
                  Ce champ est obligatoire.
                </div>
                <div *ngIf="f.confirmpassword.errors.maxlength">
                  Le mot de passe doit contenir au maximum 50 caractères.
                </div>
                <div *ngIf="f.confirmpassword.errors.pattern">
                  Le mot de passe doit comporter au moins 6 caractères, incluant au moins une lettre minuscule, 
                  une lettre majuscule, un chiffre et un caractère spécial.
                </div>
                <div *ngIf="f.confirmpassword.errors.mustMatch">
                    Le nouveau mot de passe et la confirmation de mot de passe ne correspondant pas.
                  </div>
              </div>
            </div>
        </div>
    </div>
    <div class="form-group text-center mt-3">
        <button type="submit" class="btn btn-primary">Modifier mon mot de passe</button>
    </div>
</form>
</div>

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css"
/>

