<app-header></app-header>
<div class="content-container d-flex flex-column min-vh-100">
    <div class="container-fluid bg-light">
        <div class="row">
            <div class="col-lg-12 mt-3">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a class="color-blue" routerLink="/"><i class="fa-solid fa-house"></i> Accueil</a></li>
                    <li class="breadcrumb-item active">Médecin</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <!-- Bloc de filtres -->
            <div class="col-lg-1 col-md-1 col-sm-12"></div>
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="card p-3">
                    <!-- Contenu des filtres -->
                    <div class="filters">
                        <h4>Filtres</h4>
                        <br>
                        <!-- Ajoutez vos filtres ici -->
                        <form>
                            <div class="form-group">
                                <label><i class="fas fa-user-md mr-1"></i> Nom et prénom</label>
                                <input type="text" class="form-control" id="filter1">
                            </div>
                            <div class="form-group">
                                <label><i class="fas fa-stethoscope"></i> Spécialité</label>
                                <ng-select
                                style="height: 50px"
                                [items]="specialiites"
                                class="form-control input-custom"
                                placeholder="Spécialité"
                                [closeOnSelect]="true"
                              >
                              </ng-select>
                            </div>
                            <div class="form-group">
                                <label><i class="fas fa-map-marker-alt"></i> Localisation</label>
                                <ng-select
                                style="height: 50px"
                                [items]="villes"
                                class="form-control input-custom"
                                placeholder="Localisation"
                                [closeOnSelect]="true"
                              >
                              </ng-select>
                            </div>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-primary rounded-circle" style="width: 50px; height: 50px;">
                                    <i class="fas fa-search"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Bloc de cartes -->
            <div class="col-lg-7 col-md-7 col-sm-12">
                <div class="row">
                    <div class="col-md-12">
                        <!-- <div class="card mb-3">
                            <div class="card-body">
                                <h5 class="card-title">Dr Abdallah Souilah</h5>
                                <p class="card-text">
                                    <span class="label"><i class="fas fa-stethoscope"></i> Spécialité: </span>
                                    <span class="badge bg-color badge-responsive">Généraliste</span>
                                </p>
                                <p class="card-text">
                                    <span class="label"><i class="fa-solid fa-location-dot"></i> Adresse: </span>
                                    <span>adresse <b style="color: #21726b"></b></span>
                                </p>
                                <p class="card-text">
                                    <span class="label"><i class="fa-solid fa-map-location"></i> Ville: </span>
                                    <span>tunis, Tunisie</span>
                                </p>
                                <a style="float: right" class="btn btn-connexion"><i class="fa-solid fa-location-crosshairs"></i> Itinéraire</a>
                            </div>
                        </div> -->
                        <div *ngFor="let medecin of medecinsTraitants ; let k = index" class="card mb-3">
                            <div  class="card-body">
                                <h6 class="card-title">Dr {{medecin.nom}} {{medecin.prenom}} <span class="badge bg-color badge-responsive"> {{medecin.specialite}}</span></h6>
                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <p class="card-text">
                                            <span class="label"><i class="fa-solid fa-phone"></i> Téléphone: </span>
                                            <span>{{medecin.tel}}</span>
                                        </p>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="card-text">
                                            <span class="label"><i class="fa-solid fa-envelope"></i> Email: </span>
                                            <span>{{medecin.email}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="card-text">
                                            <span class="label"><i class="fa-solid fa-location-dot"></i> Adresse: </span>
                                            <span>{{medecin.adresseCabinet.adresse}} <b style="color: #21726b"></b></span>
                                        </p>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="card-text">
                                            <span class="label"><i class="fa-solid fa-map-location"></i> Ville: </span>
                                            <span>{{medecin.adresseCabinet.ville}}, Tunisie</span>
                                        </p>
                                    </div>
                                </div>
                                <a (click)="addAppointment(medecin)" style="float: right" class="btn btn-connexion mt-3"><i class="fa-regular fa-calendar-plus"></i> Prendre un rendez-vous</a>
                            </div>
                        </div>
                     
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
