import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DocumentService } from 'src/app/_services/document.service';
import { MedecinPatientService } from 'src/app/_services/medecin-patient.service';
import { SharedService } from 'src/app/_services/shared.service';
import * as  uuid from 'uuid';

@Component({
  selector: 'app-update-document',
  templateUrl: './update-document.component.html',
  styleUrls: ['./update-document.component.css']
})
export class UpdateDocumentComponent implements OnInit {

  public files: NgxFileDropEntry[] = [];
  registerForm: FormGroup;
  submitted = false;
  listFiles = []
  myUUID = uuid.v4();
  medicalDocuments = [
    "Analyses médicales",
    "Échographies",
    "Radiographies",
    "Scanners (tomodensitométrie)",
    "IRM (Imagerie par Résonance Magnétique)",
    "Rapports médicaux antérieurs",
    "Notes opératoires",
    "Résultats de tests spécialisés"
];
statuts =["En cours" ,"Prêt"]
  filesToSaved = []
  MedecinsTraitants: any;
  documentId: string;
  documentRecupere
  nom_prenom_medecin : any


  constructor(private formBuilder: FormBuilder,
    public iziToast: Ng2IzitoastService,
    private documentService :DocumentService,
    private router: Router,
    private medecinPatientService :MedecinPatientService,
    private sharedDataService: SharedService


    ) { }

  ngOnInit(): void {
    this.documentId = this.sharedDataService.getData();
    if(!this.documentId){
      this.router.navigate(['/patient/documents' ])
    }
    this.documentService.getDocumentById(this.documentId).subscribe(data => {
      this.documentRecupere = data
      if(this.documentRecupere.demandePar){
      this.nom_prenom_medecin = this.documentRecupere.demandePar.prenom + "" + this.documentRecupere.demandePar.nom
      }
   
    this.registerForm = this.formBuilder.group({
      type: ['', Validators.required],
      date : ['', Validators.required],
      statut: ['', Validators.required],
      demandePar :[null,],
      key :['',],
      document :['',],
  },);
})
  this.getMedecinsTraitants()
  this.getListFilesNames()
  }

get f() { return this.registerForm.controls; }

onSubmit(){
    //stop here if form is invalid
    this.submitted = true
    if (this.registerForm.invalid) {
      return;
   }
    if (this.filesToSaved && this.filesToSaved.length > 0) {
  for (let file of this.filesToSaved) {
    const formData = new FormData();
    formData.append('file', file); // 'file' peut être un autre nom si requis par votre backend
  
    this.documentService.uploadFile(formData, localStorage.getItem("user"), this.documentRecupere.key).subscribe(data => {
      // handle the response for each file
    });
  }
}
      this.registerForm.value.key = this.documentRecupere.key
      let listConverteds = this.listFiles
      this.registerForm.value.document = listConverteds
      if(this.registerForm.value.demandePar){
      this.registerForm.value.demandePar = this.registerForm.value.demandePar._id
      }
      this.documentService.editDocument(this.documentId,this.registerForm.value).subscribe(data => {
        this.router.navigate(['/patient/documents' ])
      });
  
  
}


public dropped(files: NgxFileDropEntry[]) {
 
  this.files = files;
  const fileExtension = this.files[0].relativePath.split('.').pop().toLowerCase();
  const validExtensions = ['png', 'jpg' ,'jpeg','pdf','txt'];

  if (validExtensions.includes(fileExtension)) {
  this.listFiles.push(this.files[0].relativePath)
  }
  else
  {
      const targetRelativePath = this.files[0].relativePath;
      const targetIndex = files.findIndex(entry => entry.relativePath === targetRelativePath);

      if (targetIndex !== -1) {
        files.splice(targetIndex, 1);
      } 
    this.iziToast.show({
      message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
      messageColor:'#800f2f',
      titleColor:'#800f2f',
      progressBarColor:'#c9184a',
      icon:'bi-exclamation-diamond',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay:true,
      overlayClose:true,
      
    });
    return;
  }
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file
        if(file.size < 5242880){
        this.filesToSaved.push(file)
        }
        else
        {
          const targetRelativePath = this.files[0].relativePath;
          const targetIndex = this.listFiles.findIndex(entry => entry === targetRelativePath);
          if (targetIndex !== -1) {
            this.listFiles.splice(targetIndex, 1);
          } 
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay:true,
            overlayClose:true,
            
          });
          return;
        }
        //if( this.filesToSaved)
        if(this.filesToSaved.length > 4){
          this.listFiles.splice(this.filesToSaved.length-1 , 1);
          this.filesToSaved.splice(this.filesToSaved.length-1 , 1);
          this.iziToast.show({
            message: 'Vous ne pouvez pas ajouter plus de 4 fichiers.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          return;
        }
      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    }
  }
}

public fileOver(event){
}

public fileLeave(event){
}

deleteFileFromListFiles(file){
  const indexToRemove = this.listFiles.findIndex(obj => obj === file);
  if (indexToRemove !== -1) {
    this.listFiles.splice(indexToRemove, 1);
    this.filesToSaved.splice(indexToRemove, 1);
  }
   }

   getMedecinsTraitants(){
    this.medecinPatientService.getMedecinsTraitants().subscribe(data => {
    this.MedecinsTraitants = data
  })
  }
  getListFilesNames(){
    this.documentService.getListNamesFiles(this.documentId).subscribe(data =>{
      this.listFiles =data
    })
  }
}
