import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentService } from 'src/app/_services/document.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-list-documents',
  templateUrl: './popup-list-documents.component.html',
  styleUrls: ['./popup-list-documents.component.css']
})
export class PopupListDocumentsComponent implements OnInit {
  sizeContent: any;
  idDoc
  dateDoc
  keyDoc
  listFiles: any;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/data/files/documents/';

  constructor(public modal: NgbActiveModal,
    private documentService :DocumentService
  ) { }

  ngOnInit(): void {
    this.getListFilesNames()
  }
  getListFilesNames(){
    this.documentService.getListNamesFiles(this.idDoc).subscribe(data =>{
      this.listFiles =data
    })
  }

  openfile(file){
    let url =this.BaseUrl+localStorage.getItem("user")+'/'+this.keyDoc+"/"+file
    window.open(url, '_blank');
  }

  downloadFile(file) {
    let url = this.BaseUrl + localStorage.getItem("user") + '/' + this.keyDoc + "/" + file;
    const fileUrl = url;
    const fileName = file;
    const fileType = this.getFileType(file); // Ajout de cette ligne pour déterminer le type de fichier
  
    this.documentService.downloadFilePdf(fileUrl).subscribe((response) => {
      const blob = new Blob([response], { type: fileType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }, error => {
      console.error('Erreur lors du téléchargement du fichier', error);
    });
  }
  
  getFileType(fileName: string): string {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        return 'application/octet-stream'; // Type générique pour les autres fichiers
    }
  }
  
  cancel(){
    this.modal.dismiss();
  }
}
