<nav class="navbar navbar-expand-lg navbar-light bg-light pl-sm-5 pr-sm-5">
    <a class="navbar-brand" href="#"> <img src="../../../assets/medivisto bleu.png" alt="Logo" class="img-fluid" style="max-width: 70%;"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto text-center">
        <li class="nav-item">
          <a class="nav-link" routerLink="">Accueil</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/patient/rendez-vous">Rendez-vous</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/patient/medecins">Médecin</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/patient/certificats">Documents</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/patient/agenda">Agenda</a>
        </li>
      </ul>
      <ul class="navbar-nav text-center">
        <li class="nav-item dropdown">
            <a *ngIf="user" class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i  class="fa-solid fa-user"></i> {{user.prenom}} {{user.nom}}
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" routerLink="/patient/profil"><i class="fa-solid fa-user-circle"></i> Profil</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()"><i class="fa-solid fa-sign-out-alt"></i> Déconnexion</a>
            </div>
        </li>
    </ul>
    </div>
  </nav>

  
  