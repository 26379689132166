import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentUser
  user :any
  constructor(private userService :UserService,private authentificationService :AuthenticationService) {
    
   }

   ngOnInit(): void {
    
      this.currentUser = localStorage.getItem("user")
      this.userService.getUser().subscribe(user => {
        this.user = user;
      });
   }
     
  
  logout()
  {
    this.userService.disconnetUser(this.currentUser).subscribe(async data=>{
    })
    this.authentificationService.logout()
  }
}
