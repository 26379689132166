<app-header></app-header>
<div class="content-container d-flex flex-column min-vh-100">
    <div class="container-fluid pl-sm-5 pr-sm-5 bg-light">
        <div class="row">
            <div class="col-lg-12 mt-3">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item active"><span><i class="fa-solid fa-house"></i> Accueil</span></li>
                </ul>
            </div>
        </div>
        <div *ngIf="user" class="row mb-5">
            <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div class="card p-3 d-flex flex-column h-100">
                    <div class="card-header text-left">
                        <span style="color:#0090b2; font-size: 1.4rem;">
                            <i class="fa-solid fa-weight-scale" style="color:#0090b2; font-size: 2rem; margin-right: 10px;"></i> Poids
                        </span>
                    </div>
                    <div class="card-body text-center">
                        <h4 style="color:#039be5;">{{user.poids}} kg</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div class="card p-3 d-flex flex-column h-100">
                    <div class="card-header text-left">
                        <span style="color:#0090b2; font-size: 1.4rem;">
                            <i class="fa-solid fa-ruler-vertical" style="color:#0090b2; font-size: 2rem; margin-right: 10px;"></i> Taille
                        </span>
                    </div>
                    <div class="card-body text-center">
                        <h4 style="color:#039be5;">{{user.taille}} cm</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div class="card p-3 d-flex flex-column h-100">
                    <div class="card-header text-left">
                        <span style="color:#0090b2; font-size: 1.4rem;">
                            <i class="fa-solid fa-gauge" style="color:#0090b2; font-size: 2rem; margin-right: 10px;"></i> IMC
                        </span>
                    </div>
                    <div class="card-body text-center">
                        <h4 style="color:#039be5;">{{imc}}</h4>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div class="card p-3 d-flex flex-column h-100">
                    <div class="card-header text-left">
                        <span style="color:#0090b2; font-size: 1.4rem;">
                            <i class="fa-solid fa-droplet" style="color:#0090b2; font-size: 2rem; margin-right: 10px;"></i> Groupe sanguin
                        </span>
                    </div>
                    <div class="card-body text-center">
                        <h4 style="color:#039be5;">{{user.groupeSanguin}}</h4>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="mb-4">
                    <h5>Mes Rendez-vous</h5>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div class="card p-3 d-flex flex-column h-100">
                    <div class="card-header text-right">
                        <span><i class="fa-solid fa-clock"></i> 10:00 AM</span>
                    </div>
                    <div class="card-body text-center d-flex flex-column justify-content-center">
                        <h6>Dr. Omar BEN HADJ SAÏD</h6>
                        <p>Ophtalmologiste</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div class="card p-3 d-flex flex-column h-100">
                    <div class="card-header text-right">
                        <span><i class="fa-solid fa-clock"></i> 14:00 PM</span>
                    </div>
                    <div class="card-body text-center d-flex flex-column justify-content-center">
                        <h6>Dr. Dhaker Lahidheb</h6>
                        <p>Cardiologue</p>
                    </div>
                </div>
            </div>
        </div>         
        <!-- <div class="row mb-5">
            <div class="col-lg-12 col-md-6 col-sm-12">
                <div class="card p-5">
                    <div class="card-body text-center">
                        <p>Aucun rendez-vous</p>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="row mb-4">
            <div class="col-lg-6">
                <h5 class="text-left mb-4">Médecins Traitants</h5>
                <div class="card" *ngIf="medecinsTraitants && medecinsTraitants.length > 0">
                    <div class="card-body">
                        <div [ngClass]="sizeContent > 3 ? 'scroller' : 'scroller2'" class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr class="ligne-impaire">
                                        <th scope="col">Médecins Traitants</th>
                                        <th scope="col">Spécialité</th>
                                        <th scope="col" class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let medecin of medecinsTraitants ; let k = index">
                                    <tr [class.ligne-impaire]="k % 2 !== 0" [class.ligne-paire]="k % 2 === 0" >
                                        <td>Dr. {{medecin.nom}} {{medecin.prenom}}</td>
                                        <td><span class="badge badge-blue">{{medecin.specialite}}</span></td>
                                        <td class="text-center">
                                            <a (click)="addAppointment(medecin)"><i class="fa-solid fa-calendar-days"style="color: #039be5;"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card p-5" *ngIf="!medecinsTraitants || medecinsTraitants.length === 0">
                <div class="card-body text-center">
                    <p class="aucun">Vous n'avez pas des médecin Traitants</p>
                </div>
            </div>
            </div>
            <div class="col-lg-6">
                <h5 class="text-left mb-4">Traitement en Cours</h5>
                <div class="card p-5">
                    <div class="card-body text-center">
                        <p class="aucun">Aucun traitement en cours</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>