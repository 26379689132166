<app-header></app-header>
<div class="container-fluid pl-sm-5 pr-sm-5 bg-light min-vh-100 d-flex flex-column">
    <div class="row flex-grow-1 align-items-center justify-content-center">
        <div class="col-md-12 d-flex justify-content-center">
            <div class="card p-3 col-8">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h3 style="color: #0090b2 !important;">Vous avez une nouvelle demande</h3>
                            <br>
                            <h4>Le médecin <b style="text-transform: capitalize;color: black;">&nbsp; {{medecinPatient?.medecin?.nom}} {{medecinPatient?.medecin?.prenom}} &nbsp;</b> va être parmi vos médecins traitants.</h4>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12 text-center">
                            <i class="fa-solid fa-user-doctor" style="font-size: 10rem; color:#039be5;"></i>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12 text-center">
                            <h4 style="color: #0090b2 !important;">Est-ce que vous confirmez cette demande ?</h4>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-3 offset-md-3">
                            <button (click)="cancelMatching()" class="btn btn-secondary btn-block">Annuler</button>
                        </div>
                        <div class="col-md-3">
                            <button (click)="accepteDemandeMedecin()"
                                class="btn btn-primary btn-block">Confirmer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>