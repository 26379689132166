<app-header></app-header>
<div class="content-container d-flex flex-column min-vh-100">
<div class="container-fluid pl-sm-5 pr-sm-5 bg-light">
    <div class="row">
        <div class="col-lg-12 mt-3">
            <ul class="breadcrumb float-md-right">
                <li class="breadcrumb-item"><a class="color-blue" routerLink="/"><i class="fa-solid fa-house"></i>
                        Accueil</a>
                </li>
                <li class="breadcrumb-item active">Documents</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-12 mb-3">
            <h4>Modifier un document</h4>
        </div>
    </div>

    <div class="card m-3-cust">
        <div *ngIf="documentRecupere" class="card-body ml-5">
          <form  [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
          <div class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>Type de document <b style="color: red;"> * </b></label>
                <ng-select
                    [items]="medicalDocuments"
                    formControlName="type"
                    [(ngModel)]="documentRecupere.type"                  
                    class="form-control input-custom"
                    [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.type.errors }" >
                </ng-select>
                <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                    <div *ngIf="f.type.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>Date<b style="color: red;"> * </b></label>
                <input type="date" formControlName="date" class="form-control input-custom" [(ngModel)]="documentRecupere.date"    
                [ngClass]="{ 'is-invalid': submitted && f.date.errors }" />
                <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                    <div *ngIf="f.date.errors.required">Champ obligatoire.</div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>Statut<b style="color: red;"> * </b></label>
                <ng-select
                    [items]="statuts"
                    [(ngModel)]="documentRecupere.statut"
                    formControlName="statut"                  
                    class="form-control input-custom"
                    [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.statut.errors }" >
                </ng-select>
                <div *ngIf="submitted && f.statut.errors" class="invalid-feedback">
                    <div *ngIf="f.statut.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <label>Demandé par</label>
                <ng-select
                [items]="MedecinsTraitants"
                bindLabel="nom_prenom"
                [(ngModel)]="nom_prenom_medecin" 
                formControlName="demandePar"
                class="form-control input-custom"
                [closeOnSelect]="true">
                </ng-select>
            </div>
        </div>
       
        <div class="form-group row" class="center">
            <label>Fichier(s)</label>
            <ngx-file-drop  dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
            (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)"
            [ngClass]="{ 'is-invalid': submitted && files.length === 0 }">
                <ng-template  ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <div class="column text-center">
                        <a (click)="openFileSelector()"><i class="fa-solid fa-file-medical" style="font-size: 1.8rem;"></i></a>
                        <p style="color: #636363;">Déposer vos fichiers ici  </p> 
                    </div>
                </ng-template>
         
                <p style="color: #636363;">les types des fichiers doivent étre pdf,png,jpg,jpeg,txt </p> 
            </ngx-file-drop>
            <div class="upload-cards mt-3">
                <div class="row">
                  <div class="col-md-3" *ngFor="let item of listFiles; let i=index">
                    <div class="card mb-4">
                      <div class="card-body">
                        <h6 class="card-title">
                          <i class="bi bi-file-earmark-text" style="margin-right: 8px;"></i>{{ item }}
                        </h6>
                        <i (click)="deleteFileFromListFiles(item)" style="color: #0090b2 !important;cursor: pointer;" title="Supprimer" class="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <!-- <div class="upload-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Fichier :</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="listFiles" class="upload-name-style">
                      <tr *ngFor="let item of listFiles; let i=index">
                          <td><strong>{{ item }}</strong></td>
                          <td>
                          <i   style="color: #0e309f;cursor: pointer;" title="Visualiser" class="bi bi-eye"></i>
                          <i (click)="deleteFileFromListFiles(item)" style="color: #0e309f;cursor: pointer;padding-left: 5%" title="Supprimer" class="bi bi-trash"></i>
                          </td>
    
                      </tr>
                  </tbody>
                </table>
            </div> -->
          </div>
     
            <div style="padding-top: 3%;" class="text-center">
                  
                  <button routerLink="/patient/documents" class="btn btn-secondary mr-4 button_list mb-2" type="reset">Annuler</button>
                  <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
              </div>
          </form>
        </div>
      </div>


</div>
</div>
<app-footer></app-footer>
