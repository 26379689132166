import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/_services/document.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ordonnance',
  templateUrl: './ordonnance.component.html',
  styleUrls: ['/src/app/patient/files/files.css']
})
export class OrdonnanceComponent implements OnInit {

  ordonnances: any;
  dateToFiltred: any
  medecinToFiltred
  BaseUrl = environment.baseUrl.split('/api')[0] + '/uploads/documents/';
  listMedecins:any[];
  copyListDocument: any;

  constructor(
    private documentService :DocumentService,

  ) { }

  ngOnInit(): void {
    this.getOrdonnances()
  }

  getOrdonnances(){
    this.documentService.getOrdonnances().subscribe(data => {
      this.ordonnances = data;
      this.listMedecins = Array.from(
        new Set(
          this.ordonnances
            .filter(item => item.medecin && item.medecin.nom_prenom) // Filter out null or undefined demandePar
            .map(item => item.medecin.nom_prenom) // Map to nom_prenom
        )
      );
      this.copyListDocument = this.ordonnances;
  })
}

openfile(ordonnance){
   let url =this.BaseUrl+localStorage.getItem("user")+'/'+ordonnance.key+"/"+ordonnance.fileName
   window.open(url, '_blank');
}
downloadFile(ordonnance) {
  let url =this.BaseUrl+localStorage.getItem("user")+'/'+ordonnance.key+"/"+ordonnance.fileName
  const fileUrl = url;
  const fileName = ordonnance.fileName;
  const fileType = this.getFileType(ordonnance.fileName); // Ajout de cette ligne pour déterminer le type de fichier

  this.documentService.downloadFilePdf(fileUrl).subscribe((response) => {
    const blob = new Blob([response], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, error => {
    console.error('Erreur lors du téléchargement du fichier', error);
  });
}
getFileType(fileName: string): string {
  const extension = fileName.split('.').pop();
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    default:
      return 'application/octet-stream'; // Type générique pour les autres fichiers
  }
}
filter() {
  this.ordonnances = this.copyListDocument;
  let filteredDocuments = [...this.copyListDocument];
  const dateFilterLower = this.dateToFiltred ? this.dateToFiltred : '';
  const medecinFilterLower = this.medecinToFiltred ? this.medecinToFiltred :'';
  filteredDocuments = this.ordonnances.filter(item => {
    const dateFilter = dateFilterLower ? item.date.toLowerCase().includes(dateFilterLower) : true;
    const medecinFilter = medecinFilterLower? item.medecin && item.medecin.nom_prenom && item.medecin.nom_prenom.includes(medecinFilterLower): true;


    return  dateFilter && medecinFilter
  });
   this.ordonnances = filteredDocuments;
}
resetFilters() {
  this.dateToFiltred = "";
  this.medecinToFiltred = undefined;
 this.filter();
}
}
