import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/_services/user.service';
import { ageValidator, poidsValidator, tailleValidator  } from 'src/app/_helpers/validators';


@Component({
  selector: 'app-popup-update-profil-patient',
  templateUrl: './popup-update-profil-patient.component.html',
  styleUrls: ['./popup-update-profil-patient.component.css']
})
export class PopupUpdateProfilPatientComponent implements OnInit {
  defaultPays ="Tunisie"
  registerForm: FormGroup;
  submitted = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  Sexes = ['Homme','Femme']
  statusFamiliales = ['Célibataire','Marié(e)','Divorcé(e)','Veuf(ve)']
  groupeSanguins = ['A+','A-','B+','B-','AB+','AB-','O+','O-']
 typeOrgs = ['CNAM','CNSS']
  user
  patient
  @Output() profileUpdated = new EventEmitter<any>();


  constructor(private formBuilder: FormBuilder,private route :ActivatedRoute,private userService :UserService,private router :Router,
    public iziToast: Ng2IzitoastService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      nom: [
        '',
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.minLength(3),
        ],
      ],
      prenom: [
        '',
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.minLength(3),
        ],
      ],
      sexe : ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      tel: ['', Validators.required],
      profession: ['', Validators.required],
      statusFamilial: ['', Validators.required],
      typeOrg: ['', Validators.required],
      dateAnniversaire: ['', [Validators.required, ageValidator(12)]],
      taille: ['', [Validators.required, tailleValidator(50, 230)]],
      poids : ['', [Validators.required, poidsValidator(20, 300)]],
      groupeSanguin: ['', Validators.required],
    });

  }
  get f() { return this.registerForm.controls; }
onSubmit() {
  this.submitted = true;

  if (this.registerForm.invalid) {
    return;
}
this.registerForm.value.tel = this.registerForm.value.tel.internationalNumber 
this.userService.updateprofile(this.registerForm.value).subscribe(
(data) => {
 
  
  this.iziToast.show({
    message:'Mise à jour de profil avec succés',
    messageColor:'#386641',
    progressBarColor:'#6a994e',
    icon:'bi-check-circle',
    imageWidth:45,
    position:'topRight',
    timeout:5000,
    backgroundColor:'#dde5b6',
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
     });
     //this.router.navigate(['/admin/contacts' ])
     this.profileUpdated.emit(this.registerForm.value);
  this.modal.dismiss();
   })
 
 // this.modal.dismiss();
}

onReset() {
  this.modal.dismiss();

}

}
