import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private _eventURL = `${environment.baseUrl}/events`;

  constructor(private http: HttpClient) { }

  getAvailableSlotsByMedecinId(medecinId: string, date: string): Observable<any> {
    return this.http.get<any>(`${this._eventURL}?medecinId=${medecinId}&date=${date}`);
  }
  getEventsByStart(start: string): Observable<any> {
    return this.http.get<any>(`${this._eventURL}/byStart/${start}`);
  }
  toggleLibre(eventId: string): Observable<any> {
    return this.http.put<any>(`${this._eventURL}/toggleLibre/${eventId}`, {});
  }
}
