import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MedecinPatientService {

  private _medecinPatientUrl=`${environment.baseUrl}/medecinsPatients`
  constructor(private http: HttpClient) { }
 
  // patient add a other file
     
       validationMatching(id :String,medecinPatient: any):Observable<Object> {
         return this.http.put(`${this._medecinPatientUrl}/validationMatching/${id}`,medecinPatient);
       }

      getById(id: string): Observable<any> {
    return this.http.get<any>(`${this._medecinPatientUrl}/${id}`);
      }
      deleteMatching(id : string)
      {
        return this.http.delete(`${this._medecinPatientUrl}/deleteMatching/${id}`);
      } 

      getMedecinsTraitants(): Observable<any> {
        return this.http.get<any>(`${this._medecinPatientUrl}/medecinsTraitants`);
          }


}
