import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';
import { catchError, map, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  //private _installerURL="http://localhost:3200/api/fitters" 
  private apiUrl = 'http://api.geonames.org';
  private _userURL=`${environment.baseUrl}/users`
  constructor(private http: HttpClient) { }


  stayConnected(email : string):Observable<User>
  {
    return this.http.get<User>(`${this._userURL}/stayConnected/${email}`);

  } 
  uploadImage(files,key):Observable <any> {
    return this.http.post(`${this._userURL}/upload/img/${key}`,files);
        }
 
     getUserrById(id : string):Observable<User>
      {
        return this.http.get<User>(`${this._userURL}/${id}`);
    
      } 
      
      EditUser(id:string,user:User):Observable<Object> {
        return this.http.put(`${this._userURL}/${id}`,user);
      }

      EditPasswordUser(user:User):Observable<Object> {
        return this.http.put(`${this._userURL}/updatePassowrd`,user);
      }

      disconnetUser(id : string):Observable<User>
      {
        return this.http.get<User>(`${this._userURL}/disconnect/${id}`);
    

        
      } 

      getUser() {
        const userData = localStorage.getItem('user');
        if (userData) {
          return this.getUserrById(userData)
      }
    }

      getPatientByToken(token : string):Observable<User>
      {
        return this.http.get<User>(`${this._userURL}/getuserByToken/${token}`);
    
      } 


      majImage(id : string,user):Observable<User>
      {
        return this.http.put<User>(`${this._userURL}/majImage/${id}`,user);
      } 

      updatePassowrd(user):Observable<User>
      {
        return this.http.put<User>(`${this._userURL}/update/Passowrd/`,user);
      } 

      updateprofile(user):Observable<User>
      {
        return this.http.put<User>(`${this._userURL}/update/info/profil/`,user);
      } 





      
   

}
