import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SanteDetailsService } from 'src/app/_services/sante-details.service';

@Component({
  selector: 'app-popup-add-vaccinations',
  templateUrl: './popup-add-vaccinations.component.html',
  styleUrls: ['./popup-add-vaccinations.component.css']
})
export class PopupAddVaccinationsComponent implements OnInit {
  vaccins = [
    "Rougeole, Oreillons, Rubéole (ROR)",
    "Varicelle",
    "Polio (IPV)",
    "Hépatite A",
    "Grippe (certaines versions)",
    "Hépatite B",
    "Méningocoque",
    "Papillomavirus humain (HPV)",
    "Tétanos",
    "Diphtérie",
    "COVID-19 (Pfizer-BioNTech, Moderna)",
    "COVID-19 (AstraZeneca, Johnson & Johnson)",
]
    maladiesChoqs :any 
    vacs : any // la liste des maldies chroniques d'un patient qui ont dans la base 
    algs :any
  registerForm: any;
  submitted: boolean =false ;
  @Output() dossierMedicaleUpdated = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,private route :ActivatedRoute,private santeDetailService :SanteDetailsService,private router :Router,
    public iziToast: Ng2IzitoastService,
    public modal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      maladiesChroniques: [''],
      allergies: [''],
      vaccins: [''],
    });

  }
  get f() { return this.registerForm.controls; }
onSubmit() {
  this.submitted = true;

  if (this.registerForm.invalid) {
    return;
}
this.registerForm.value.allergies = this.algs
this.registerForm.value.maladiesChroniques = this.maladiesChoqs
this.santeDetailService.ajoutInfoSante(this.registerForm.value).subscribe(
(data) => {
  this.dossierMedicaleUpdated.emit(this.registerForm.value);
  this.modal.dismiss();
   })
 
 this.modal.dismiss();
}

onReset() {
  this.modal.dismiss();

}

cancel(){
  this.modal.dismiss();
}

}
