import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
 //private _installerURL="http://localhost:3200/api/fitters" 
 private _documentURL=`${environment.baseUrl}/document`
 constructor(private http: HttpClient) { }

 // patient add a other file
    uploadFile(file,idPatient,key):Observable <any> {
      return this.http.post(`${this._documentURL}/upload/file/${idPatient}/${key}`,file);
    }

       createDocument(document: any):Observable<Object> {
        return this.http.post(`${this._documentURL}`,document);
      }

      getDocuments():Observable<any>
      {
        return this.http.get<any>(`${this._documentURL}`);
      } 

      //
      downloadFilePdf(url: string): Observable<Blob> {
        return this.http.get(url, { responseType: 'blob' });
      }

      deleteDocumentByCurrentPatient(id : string)
      {
        return this.http.delete(`${this._documentURL}/${id}`);
      } 

      getListNamesFiles(id :string){
        return this.http.get<any>(`${this._documentURL}/files/listNames/${id}`);
      }

      getListesMedecinsPartages(id :string){
        return this.http.get<any>(`${this._documentURL}/listesMedecinsPartages/${id}`);
      }

      shareDocment(id:string,document:any):Observable<Object> {
        return this.http.put(`${this._documentURL}/partage/documents/avecMedecins/${id}`,document);
      }

      getDocumentById(id :string){
        return this.http.get<any>(`${this._documentURL}/data/${id}`);
      }

      editDocument(id:string,document:any):Observable<Object> {
        return this.http.put(`${this._documentURL}/update/document/${id}`,document);
      }

      getOrdonnances():Observable<any>
      {
        return this.http.get<any>(`${this._documentURL}/list/Ordonnances/by/currentPatient`);
      } 

      getCertificats():Observable<any>
      {
        return this.http.get<any>(`${this._documentURL}/list/Certificats/by/currentPatient`);
      } 


    
}
