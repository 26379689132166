import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { RdvService } from 'src/app/_services/rdv.service';
import { EventService } from 'src/app/_services/event.service';

@Component({
  selector: 'app-popup-update-appointment',
  templateUrl: './popup-update-appointment.component.html',
  styleUrls: ['./popup-update-appointment.component.css']
})
export class PopupUpdateAppointmentComponent implements OnInit {
  @Input() rdvId: string;
  @Output() rdvupdate = new EventEmitter<void>();
  currentYear: number;
  currentDate: Date;
  calendarDays: any[] = [];
  timeSlots: any[] = [];
  dateToSend: any;
  timeToSend: any;
  rdv: any = {};
  medecin: any = {};
  dateSelected: boolean = true; 
  timeSelected: boolean = true;
  Motifs = ['Motif1','Motif2','Motif3','Motif4'] 
  selectedMotif: string;

  constructor(
    public modal: NgbActiveModal,
    private rdvService: RdvService,
    private eventService: EventService,
    public iziToast: Ng2IzitoastService
  ) {
    this.currentYear = new Date().getFullYear();
    this.currentDate = new Date();
  }

  ngOnInit(): void {
    if (this.rdvId) {
      this.getRdvDetails(this.rdvId);
    } else {
      this.generateCalendar();
    }
  }

  getRdvDetails(id: string): void {
    this.rdvService.getRdvById(id).subscribe(
      (response) => {
        this.rdv = response;
        this.medecin = this.rdv.medecinId;
        this.dateToSend = new Date(this.rdv.start).toISOString().split('T')[0];
        this.timeToSend = new Date(this.rdv.start).toTimeString().split(' ')[0].substring(0, 5);
        this.currentDate = new Date(this.dateToSend);
        this.selectedMotif = this.rdv.motif.length > 0 ? this.rdv.motif[0] : null; // Assurez-vous que selectedMotif est une chaîne
        this.generateCalendar();
        this.generateTimeSlots(this.currentDate); // Pass the current date to generate time slots
      },
      (error) => {
        console.error('Erreur lors de la récupération du rendez-vous', error);
      }
    );
  }
  

  cancel() {
    this.modal.dismiss();
  }

  previousMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.generateCalendar();
  }

  nextMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.generateCalendar();
  }

  previousWeek() {
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    this.generateCalendar();
  }

  nextWeek() {
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.generateCalendar();
  }

  selectDay(day) {
    if (!day.isPast && day.isAvailable) {
      this.dateToSend = day.date;
      this.calendarDays.forEach(d => d.active = false);
      day.active = true;
      this.dateSelected = true;
      this.timeSelected = false; // Reset time selection when changing the date
      this.timeToSend = null; // Clear the previously selected time
      this.generateTimeSlots(day.date); // Generate time slots for the selected date
    }
  }
  selectTimeSlot(slot) {
    this.timeSlots.forEach(s => s.active = false);
    slot.active = true;
    this.timeToSend = slot.time;
    this.timeSelected = true;
  }

  generateCalendar() {
    const startOfWeek = new Date(this.currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

    this.calendarDays = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeek);
      date.setDate(date.getDate() + i);
      this.calendarDays.push({
        dayName: date.toLocaleString('default', { weekday: 'short' }),
        dayNumber: date.getDate(),
        monthName: date.toLocaleString('default', { month: 'short' }),
        date: date,
        isPast: date < new Date() && date.toDateString() !== new Date().toDateString(),
        isAvailable: true,
        active: this.dateToSend ? date.toDateString() === new Date(this.dateToSend).toDateString() : false
      });
    }
  }

  generateTimeSlots(date?: Date) {
    const medecinId = this.medecin._id;
    const formattedDate = date ? date.toISOString().split('T')[0] : null;
  
    if (formattedDate) {
      this.eventService.getAvailableSlotsByMedecinId(medecinId, formattedDate).subscribe(
        (slots) => {
          this.timeSlots = slots
            .filter(slot => slot.libre) // Filtrer par créneaux disponibles
            .map(slot => ({
              id: slot._id,
              time: slot.start.split('T')[1].substring(0, 5),
              active: this.timeToSend === slot.start.split('T')[1].substring(0, 5) // Compare and set active
            }))
            .sort((a, b) => a.time.localeCompare(b.time)); // Trier les créneaux horaires par ordre croissant
        },
        (error) => {
          this.iziToast.error({
            message: 'Erreur lors de la récupération des créneaux disponibles',
            position: 'topRight'
          });
        }
      );
    }
  }
  
  

  updateRdv() {
    if (this.dateToSend && this.timeToSend && this.selectedMotif) {
      const date = new Date(this.dateToSend);
  
      if (!isNaN(date.getTime())) {
        const year = date.getFullYear();
        const month = this.padZero(date.getMonth() + 1);
        const day = this.padZero(date.getDate());
        const formattedDate = `${year}-${month}-${day}`;
  
        const time = this.timeToSend;
        const start = `${formattedDate}T${time}:00`;
        this.rdv.start = start;
        this.rdv.medecinId = this.medecin._id;
        this.rdv.motif = [this.selectedMotif];  // Ensure motif is included here
  
        this.rdvService.updateRdv(this.rdvId, this.rdv).subscribe(
          (data) => {
            this.iziToast.show({
              message: 'Rendez-vous modifié avec succès',
              messageColor: '#386641',
              progressBarColor: '#6a994e',
              icon: 'bi-check-circle',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#dde5b6',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
            });
            this.rdvupdate.emit();
            this.modal.close();
          },
          (error) => {
            this.iziToast.show({
              message: 'Erreur lors de la modification du rendez-vous',
              messageColor: '#d9534f',
              progressBarColor: '#d9534f',
              icon: 'bi-x-circle',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#f5c6cb',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
            });
          }
        );
  
        this.eventService.toggleLibre(this.timeToSend.id).subscribe(
          (data) => console.log(data),
          (error) => console.error('Erreur lors du changement de statut', error)
        );
      }
    }
  }
  

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
