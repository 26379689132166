<app-header></app-header>
<div class="content-container d-flex flex-column min-vh-100">
    <div class="container-fluid pl-sm-5 pr-sm-5 bg-light">
        <div class="row">
            <div class="col-lg-12 mt-3">
                <ul class="breadcrumb float-md-right">
                    <li class="breadcrumb-item"><a class="color-blue" routerLink="/"><i class="fa-solid fa-house"></i>
                            Accueil</a>
                    </li>
                    <li class="breadcrumb-item active">Documents</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-12 mb-3">
                <h4>Mes documents</h4>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <button routerLink="ajout" class="btn btn-primary">Ajouter un document</button>
                </div>
            </div>
        </div>
        <br>
        <div class="card  p-3 mb-4">
            <div class=" col-12">
                <div class="row">
                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
                        <h5 routerLink="/patient/certificats" class="titleotherpage">Certificats</h5>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
                        <h5 routerLink="/patient/ordonnance" class="titleotherpage">Ordonnances</h5>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 border-cust">
                        <h5 class="titlepage">Autres documents</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="card p-2">
            <div class="row mt-4">
                <div class="col-md-12 mb-3 mb-md-0">
                    <div class="form-group">
                        <div class="col row ">
                            <ng-select [items]="medicalDocuments" placeholder="Type" class="col form-control mr-2"
                                [closeOnSelect]="true" [(ngModel)]="typeToFiltred" (change)="filter()">
                            </ng-select>
                            <input type="date" class="col form-control mr-2" placeholder="Date"
                                [(ngModel)]="dateToFiltred" (input)="filter()">
                            <ng-select [items]="statuts" placeholder="Statut" class="col form-control mr-2"
                                [closeOnSelect]="true" [(ngModel)]="statutToFiltred" (change)="filter()">
                            </ng-select>
                            <ng-select [items]="listMedecins" placeholder="Demandé par" class="col form-control mr-2"
                                [closeOnSelect]="true" (change)="filter()" [(ngModel)]="medecinToFiltred">
                            </ng-select>
                            <button (click)="resetFilters()" class="btn btn-primary p-2 mr-1">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                    viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                    <style>
                                        svg {
                                            fill: #ffffff
                                        }
                                    </style>
                                    <path
                                        d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <div class="row p-3">
                        <div *ngFor="let doc of documents | paginate: { itemsPerPage: tableSize, currentPage: page, totalItems: count }; let k = index"
                            class="col-12 col-md-2 mt-4 mb-4 d-flex">
                            <div class="card flex-fill p-2 card-hover">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <div>
                                        <!-- Icone de switch -->

                                        <!-- Badge de statut -->
                                        <span *ngIf="doc.statut == 'En cours'"
                                            class="badge bg-orange p-2 text-truncate">{{doc.statut}}</span>
                                        <span *ngIf="doc.statut == 'Prêt'"
                                            class="badge bg-green p-2 text-truncate">{{doc.statut}}</span>
                                        <!-- <span class="ml-2">{{doc.date}} </span> -->
                                    </div>
                                    <div>
                                        <a (click)="shareDocumentWithDoctors(doc._id)"
                                            [class.disabled]="doc.statut === 'En cours'">
                                            <i class="fa-solid fa-share download-icon mr-2"
                                                title="Partager le document"></i>
                                        </a>
                                        <a (click)="modifyDocument(doc._id)">
                                            <i class="fa-solid fa-pencil download-icon mr-2"
                                                title="Modifier le document"></i>
                                        </a>
                                        <a (click)="getdocument(doc)" data-toggle="modal"
                                            data-target="#exampleModalArchiver">
                                            <i class="fa-solid fa-trash download-icon"
                                                title="Supprimer le document"></i>
                                        </a>
                                    </div>
                                </div>

                                <div class="card-body text-center">
                                    <a (click)="viewListDocument(doc._id,doc.date,doc.key)">
                                        <i class="fa-solid fa-file-medical download-icon"
                                            style="color:#0090b2; font-size: 4rem;"></i>
                                    </a>
                                    <p class="card-title mt-lg-3">
                                    </p>
                                    <span class="badge bg-blue p-2 text-truncate">{{doc.type}}</span>
                                    <span *ngIf="doc.demandePar" class="badge bg-blueCiel p-2 ml-2 text-truncate">
                                        <i class="fa-solid fa-user-doctor"></i> Dr. {{doc.demandePar.nom}}
                                        {{doc.demandePar.prenom}}</span>
                                    <span *ngIf="!doc.demandePar" class="badge bg-grayAucun p-2 ml-2 text-truncate">
                                        <i class="fa-solid fa-user-doctor"></i> Aucun docteur n'a demandé</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="documents && documents.length > 10" class="border-0 mt-4">
                        <div class="d-flex justify-content-center">
                            <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant"
                                (pageChange)="onTableDataChange($event)">
                            </pagination-controls>
                        </div>
                    </div>

                    <div *ngIf="documents && documents.length == 0" class="table-responsive">
                        <p class="text-center p-5 aucun">
                            Aucun document n'est trouvé
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>



<div class="modal fade" id="exampleModalArchiver" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static">

    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Confirmation de suppresion</h4>
                <a class="exit" data-dismiss="modal" aria-label="Close"><i class="fa-regular fa-circle-xmark"></i></a>
            </div>
            <div class="modal-body">
                <div>
                    <form class="">
                        <div class="form-row justify-content-center align-items-center ">
                            <p class="text-center">Êtes-vous sûr(e) de vouloir supprimer ce document ?</p>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                            <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                                <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button>
                                <button (click)="deleteDocument(documentDeleted)" class="btn btn-primary"
                                    data-dismiss="modal">Supprimer</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>