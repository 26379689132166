import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { ageValidator, poidsValidator, tailleValidator } from 'src/app/_helpers/validators';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-fill-inscription',
  templateUrl: './fill-inscription.component.html',
  styleUrls: ['./fill-inscription.component.css']
})
export class FillInscriptionComponent implements OnInit {
  defaultPays ="Tunisie"
  registerForm: FormGroup;
  submitted = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  Sexes = ['Homme','Femme']
  statusFamiliales = ['Célibataire','Marié(e)','Divorcé(e)','Veuf(ve)']
  groupeSanguins = ['A+','A-','B+','B-','AB+','AB-','O+','O-']
  defaultCity ="Tunisie"
  typeOrgs = ['CNAM','CNSS']
  governorates: any [];
  delegs: any [];
  cites :any
  codePostalChoise :any =""
  token
  user

  constructor(private formBuilder: FormBuilder,private route :ActivatedRoute,private userService :UserService,private router :Router,public iziToast: Ng2IzitoastService,
  ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.params["token"];
    this.userService.getPatientByToken(this.token).subscribe(data => {
      if(data['message'] == 'User  is not found'){
        localStorage.clear();
        this.router.navigate(['/connexion' ])
        return ;
      }
      this.user = data;
      if(this.user.dateAnniversaire){
        localStorage.clear();
        this.router.navigate(['/connexion' ])
        return ;
      }
       },)

    this.registerForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')
      ]],
      confirmationPassword: ['', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_]).{6,}$')
      ]],
      sexe : ['', Validators.required],
      profession: ['', Validators.required],
      statusFamilial: ['', Validators.required],
      typeOrg: ['', Validators.required],
      dateAnniversaire: ['', [Validators.required, ageValidator(12)]],
      taille: ['', [Validators.required, tailleValidator(50, 230)]],
      poids : ['', [Validators.required, poidsValidator(20, 300)]],
      groupeSanguin: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmationPassword')
    });



  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }

    this.userService.EditUser(this.user._id,this.registerForm.value).subscribe(
      (data) => {
       
        
        this.iziToast.show({
          message:'Inscription faite à la plateforme avec succès',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
           this.router.navigate(['/connexion' ])
          })
  
   
    // display form values on success
    //
  
  }



}
