import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MedecinPatientService } from 'src/app/_services/medecin-patient.service';

@Component({
  selector: 'app-validation-medecin',
  templateUrl: './validation-medecin.component.html',
  styleUrls: ['./validation-medecin.component.css']
})
export class ValidationMedecinComponent implements OnInit {
id 
medecinPatient :any
  constructor(private medecinPatientService :MedecinPatientService,
    private route :ActivatedRoute ,
    private router :Router,
    public iziToast: Ng2IzitoastService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.medecinPatientService.getById(this.id).subscribe(
      data => {
        if(data["msg"] == "Le délai de confirmation est dépassé"){
          this.iziToast.show({
            message: "Le délai de confirmation est dépassé",
            messageColor: '#FF0000',
            progressBarColor: '#FF6347',
            icon: 'bi-exclamation-circle',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#f8d7da',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
          });
          this.router.navigate(['/patient' ])
          return ;
        }
        this.medecinPatient = data;
        if ( this.medecinPatient.validePatient == true ){
          this.iziToast.show({
            message: "Cette demande est déjà confirmé.",
            messageColor: '#FF0000',
            progressBarColor: '#FF6347',
            icon: 'bi-exclamation-circle',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#f8d7da',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
          });
          this.router.navigate(['/patient' ])
          return ;
        }
       
      },
      error => {
        this.router.navigate(['/patient' ])
        return ;
      }
    );
  }

 
  accepteDemandeMedecin(){
    this.medecinPatientService.validationMatching(this.id,this.medecinPatient).subscribe(data => {
      this.iziToast.show({
        message:'Votre confirmation est effectuée avec succès.',
        messageColor:'#386641',
        progressBarColor:'#6a994e',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
         this.router.navigate(['/patient' ])
    })
}


cancelMatching(){
  this.medecinPatientService.deleteMatching(this.id).subscribe(data => {
    let msg = "La demande d'ajout du dr"+ " " +this.medecinPatient.medecin.nom + " " +this.medecinPatient.medecin.prenom +" " +"à votre liste de médecin traitant a été refusé."
    this.iziToast.show({
      message:msg,
      messageColor:'#386641',
      progressBarColor:'#6a994e',
      icon:'bi-check-circle',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#dde5b6',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
       });
       this.router.navigate(['/patient' ])
  })
}
}
