import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function tailleValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = Number(control.value);
    if (isNaN(value) || value < min || value > max) {
      return { tailleInvalid: true };
    }
    return null;
  };
}

export function poidsValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = Number(control.value);
      if (isNaN(value) || value < min || value > max) {
        return { tailleInvalid: true };
      }
      return null;
    };
  }

  export function ageValidator(minAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDate = new Date(control.value);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
  
      // Adjust the age if the birth month hasn't occurred yet this year
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
  
      return age >= minAge ? null : { tooYoung: true };
    };
  }
