<div class="modal-header">
    <h5 class="modal-title">Liste des fichiers</h5>
      <button
      type="button"
      class="btn-close exit-button"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ><i class="fa-regular fa-circle-xmark"></i>
    </button>
  </div>
       
  <div class="modal-body">
    <div *ngIf="listFiles && listFiles.length > 0" [ngClass]="sizeContent > 5 ? 'scroller' : 'scroller2'" class="table-responsive">
        <table class="table">
            <thead>
                <tr class="ligne-impaire">
                    <th scope="col">Fichier</th>
                    <th scope="col">Date</th>
                    <th scope="col" class="text-center">Actions</th>
                </tr>
            </thead>
            <tbody *ngFor="let file of listFiles; let k = index">
                <tr [class.ligne-impaire]="k % 2 !== 0" [class.ligne-paire]="k % 2 === 0" >
                    <td>{{file}}</td>
                    <td><span class="badge badge-blue">{{dateDoc|date:'dd/MM/yyyy'}}</span></td>
                    <td class="text-center">
                        <a class="mr-2" (click)="openfile(file)"><i class="fa-solid fa-eye"style="color: #039be5;"></i></a>
                        <a (click)="downloadFile(file)"><i class="fa-solid fa-download"style="color: #039be5;"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
        
    </div>
    <div *ngIf="listFiles && listFiles.length == 0" class="table-responsive">
        <p class="text-center p-5 aucun">
            Aucun fichier n'a été déposé pour ce document.
        </p>
    </div>

</div>
       
