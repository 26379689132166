<div class="modal-header">
    <h5 class="modal-title">Dossier médicale - Allergies</h5>
      <button
      type="button"
      class="btn-close exit-button"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ><i class="fa-regular fa-circle-xmark"></i>
    </button>
  </div>
       
  <div class="modal-body">
    <div>
     <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Allergies:</label>
            <ng-select 
                [items]="allergiesList"
                class="form-control"
                bindLabel="typeAllergie"
                bindValue="typeAllergie"
                [multiple]="true"
                (change)="createListAllergies($event)"> 
            </ng-select>      
        </div>
        
        <div class="form-row">
            <div class="col-1"></div>
            <div class="col-11">
                <div *ngFor="let item of typesAlergiesSelcted" class="form-group">
                    <label style="color:#039be5;">{{item.typeAllergie}}:</label>
                    <ng-select [items]="item.nature"
                    class="form-control "
                    (change)="createListAllergiesWithNatures(item.typeAllergie,$event)"
                    [closeOnSelect]="true"
                    multiple = true 
                    >
                   </ng-select>      
                </div>
            </div>        
        </div>
      <div class="form-group text-center mt-2">
         <button (click)="cancel()" type="reset" class="btn btn-secondary mr-3">Annuler</button>
         <button type="submit" class="btn btn-primary">Enregistrer</button>
      </div>
    </form>

</div>
</div>
       

