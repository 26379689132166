import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { DocumentService } from 'src/app/_services/document.service';
import { SharedService } from 'src/app/_services/shared.service';
import { PopupListDocumentsComponent } from 'src/app/popus/documents/popup-list-documents/popup-list-documents.component';
import { PopupPartageDocumentComponent } from 'src/app/popus/documents/popup-partage-document/popup-partage-document.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['/src/app/patient/files/files.css']
})
export class DocumentsComponent implements OnInit {
  documents: any;
  BaseUrl = environment.baseUrl.split('/api')[0] + '/uploads/documents/';
  copyListDocument: any;
  page: number = 1;
  tableSize: number = 10;
  tableSizes: any = [5, 10, 15, 20];
  count: number = 0;

  medicalDocuments = [
    "Analyses médicales",
    "Échographies",
    "Radiographies",
    "Scanners (tomodensitométrie)",
    "IRM (Imagerie par Résonance Magnétique)",
    "Rapports médicaux antérieurs",
    "Notes opératoires",
    "Résultats de tests spécialisés"
];
statuts =["En cours" ,"Prêt"]
listMedecins :any


 statutToFiltred: any
 typeToFiltred: any
  dateToFiltred: any
  medecinToFiltred
  documentDeleted: any;

  constructor(
    private documentService :DocumentService,
    private modalService: NgbModal,
    public iziToast: Ng2IzitoastService,
    private sharedDataService: SharedService,
     private router: Router

  ) { }

  ngOnInit(): void {
    this.getOtherDocuments()
  }

  getOtherDocuments(){
    this.documentService.getDocuments().subscribe(data => {
      this.documents = data;
      this.copyListDocument = this.documents;
      this.listMedecins = Array.from(
        new Set(
          this.documents
            .filter(item => item.demandePar && item.demandePar.nom_prenom) // Filter out null or undefined demandePar
            .map(item => item.demandePar.nom_prenom) // Map to nom_prenom
        )
      );
            

    });
  }
  onTableDataChange(event: any) {
    this.page = event;

  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
  }

  filter() {
    this.documents = this.copyListDocument;
    let filteredDocuments = [...this.copyListDocument];
    const typeFilterLower = this.typeToFiltred ? this.typeToFiltred.toLowerCase() : '';
    const statutFilterLower = this.statutToFiltred ? this.statutToFiltred.toLowerCase() : '';
    const dateFilterLower = this.dateToFiltred ? this.dateToFiltred : '';
    const medecinFilterLower = this.medecinToFiltred ? this.medecinToFiltred :'';
    filteredDocuments = this.documents.filter(item => {
      const documentFilter = statutFilterLower ? item.statut.toLowerCase().includes(statutFilterLower) : true;
      const typeFilter = typeFilterLower ? item.type.toLowerCase().includes(typeFilterLower) : true;
      const dateFilter = dateFilterLower ? item.date.toLowerCase().includes(dateFilterLower) : true;
      const medecinFilter = medecinFilterLower? item.demandePar && item.demandePar.nom_prenom && item.demandePar.nom_prenom.includes(medecinFilterLower): true;
  
  
      return   documentFilter && typeFilter && dateFilter && medecinFilter
    });
     this.documents = filteredDocuments;
  
     // Reset pagination
     this.page = 1;
  }

  resetFilters() {
    this.statutToFiltred = undefined
    this.typeToFiltred = undefined; 
    this.dateToFiltred = "";
    this.medecinToFiltred = undefined;
   this.filter();
  }
  getdocument(doc){
    this.documentDeleted = doc
  }

  deleteDocument(doc){
    doc = this.documentDeleted
    this.documentService.deleteDocumentByCurrentPatient(this.documentDeleted._id).subscribe((e) => {
      this.iziToast.show({
        message: "Suppression avec succès",
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        icon: 'bi-check-circle',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
       this.getOtherDocuments()
       this.page = 1
    })

  }
  viewListDocument(idDoc,dateDoc,keyDoc){
    const modalRef = this.modalService.open(PopupListDocumentsComponent);
    modalRef.componentInstance.idDoc = idDoc
    modalRef.componentInstance.dateDoc = dateDoc
    modalRef.componentInstance.keyDoc = keyDoc
  }

  shareDocumentWithDoctors(idDocument){
    const modalRef = this.modalService.open(PopupPartageDocumentComponent);
    modalRef.componentInstance.idDoc = idDocument
  }

  modifyDocument(docId: string): void {
    this.sharedDataService.setData(docId);
    this.router.navigate(['/patient/documents/modification']);
  }
 

}
