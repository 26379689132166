<div class="modal-header">
    <h5 class="modal-title">Modification des informations personnelles</h5>
    <button
      type="button"
      class="btn-close exit-button"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ><i class="fa-regular fa-circle-xmark"></i>
    </button>
    <!-- <a class="exit" data-dismiss="modal" aria-label="Close"><i class="fa-regular fa-circle-xmark"></i></a> -->
  </div>
       
  <div class="modal-body">
    <div>
     <form #f0="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-row">
            <div class="form-group col-md-4">
              <label for="firstname">Nom <b style="color: red">*</b></label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                class="form-control"
                [(ngModel)]="patient.nom"
                formControlName="nom"
                [ngClass]="{ 'is-invalid': submitted && f.nom.errors }"
                onkeypress="return /[a-zA-Zéèçàâ ]/.test(event.key)"
                />
                <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                  <div *ngIf="f.nom.errors.required">Ce champ est obligatoire.</div>
                  <div *ngIf="f.nom.errors.minlength">
                    Ce champ doit avoir minimum 3 caractères.
                  </div>
                  <div *ngIf="f.nom.errors.maxlength">
                    Ce champ ne doit pas dépasser 25 caractères.
                  </div>
                </div>
            </div>
            <div class="form-group col-md-4">
              <label for="lastname">Prénom <b style="color: red">*</b></label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                [(ngModel)]="patient.prenom"
                class="form-control"
                formControlName="prenom"
                [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }"
                onkeypress="return /[a-zA-Zéèçàâ ]/.test(event.key)"
                />
                <div *ngIf="submitted && f.prenom.errors" class="invalid-feedback">
                  <div *ngIf="f.prenom.errors.required">
                    Ce champ est obligatoire.
                  </div>
                  <div *ngIf="f.prenom.errors.minlength">
                    Ce champ peut comporter au mimunimn 3 caractères.
                  </div>
                  <div *ngIf="f.prenom.errors.maxlength">
                    Ce champ ne doit pas dépasser 25 caractères.
                  </div>
                </div>
            </div>
            <div class="form-group col-md-4">
                    <label>Sexe:</label>
                    <ng-select [items]="Sexes" [(ngModel)]="patient.sexe"
                    class="form-control "
                    [closeOnSelect]="true"
                    formControlName="sexe"
                    [ngClass]="{ 'is-invalid': submitted && f.sexe.errors }"
                    >
                   </ng-select>      
                   <div *ngIf="submitted && f.sexe.errors" class="invalid-feedback">
                    <div *ngIf="f.sexe.errors.required">Ce champ est obligatoire.</div>
                  </div>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email <b style="color: red">*</b></label>
            <input
              type="email"
              id="email"
              name="email"
              class="form-control"
              disabled
              formControlName="email"
              [(ngModel)]="patient.email"
            />
          </div>
          <div class="row">
            <div class="form-group col">
              <label>Téléphone</label>
              <ngx-intl-tel-input
              [cssClass]="'form-control input-custom-phone'"
              formControlName="tel"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Tunisia"
              [(ngModel)]="patient.tel"  
              [maxLength]="15"
              [phoneValidation]="true"
              name="tel"
             
            ></ngx-intl-tel-input>
            <div *ngIf="registerForm.value.tel && !f0.form.controls['tel'].invalid == false" style="color: red;">
              Numéro de téléphone non validé <i class="bi bi-x"></i>
          </div>
          <div *ngIf=" registerForm.value.tel && !f0.form.controls['tel'].invalid == true" style="color: green;">
            Numéro de téléphone  validé <i class="bi bi-check"></i>
             </div>
            
          </div>
        </div>
                    
        <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label>Profession:</label>
                                <input type="text" class="form-control" formControlName="profession" 
                                onkeypress="return /[a-z A-Z]/.test(event.key)" [(ngModel)]="patient.profession"
                                [ngClass]="{'is-invalid': submitted && f.profession.errors}">
                                <div *ngIf="submitted && f.profession.errors"
                                class="invalid-feedback" >       
                                <div *ngIf="f.profession.errors.required">
                                Ce champ est obligatoire.
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Statut familial:</label>
                                <ng-select [items]="statusFamiliales" [(ngModel)]="patient.statusFamilial"
                                class="form-control "
                                [closeOnSelect]="true"
                                formControlName="statusFamilial"
                                [ngClass]="{ 'is-invalid': submitted && f.statusFamilial.errors }"
                                >
                           </ng-select>
                                <div
                                *ngIf="submitted && f.statusFamilial.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.statusFamilial.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                </div>
                            </div>
                        </div>
        </div>
         <div class="form-row">
                        <div class="col-6">
                            <div class="form-group">
                                <label>Date de naisssance:</label>
                                <input type="date" class="form-control" formControlName="dateAnniversaire" [(ngModel)]="patient.dateAnniversaire"
                                onkeypress="return /[0-9]/.test(event.key)"
                                [ngClass]="{'is-invalid': submitted && f.dateAnniversaire.errors}">
                                <div
                                *ngIf="submitted && f.dateAnniversaire.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.dateAnniversaire.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                <div *ngIf="f.dateAnniversaire.errors.tooYoung">
                                  L'âge doit être au moins de 12 ans.
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Type d'organisme:</label>
                                <ng-select [items]="typeOrgs"
                                formControlName="typeOrg" [(ngModel)]="patient.typeOrg"
                                class="form-control input-custom"
                                [closeOnSelect]="true"
                                [ngClass]="{ 'is-invalid': submitted && f.typeOrg.errors }"
                                >
                                </ng-select>
                                <div
                                *ngIf="submitted && f.typeOrg.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.typeOrg.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                </div>
                            </div>
                        </div>
                
          </div>
        <div class="form-row">
                        <div class="col">
                            <div class="form-group">
                                <label>Taille(cm):</label>
                                <input type="text" class="form-control"  formControlName="taille" [(ngModel)]="patient.taille"
                                onkeypress="return /[0-9]/.test(event.key)"
                                [ngClass]="{ 'is-invalid': submitted && f.taille.errors }"
                                >
                                <div
                                *ngIf="submitted && f.taille.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.taille.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                <div *ngIf="f.taille.errors.tailleInvalid">
                                  La taille doit être comprise entre 50 cm et 230 cm.
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Poids(kg):</label>
                                <input type="text" class="form-control" formControlName="poids" [(ngModel)]="patient.poids"
                                onkeypress="return /[0-9]/.test(event.key)" 
                                [ngClass]="{ 'is-invalid': submitted && f.poids.errors }"
                                >
                                <div
                                *ngIf="submitted && f.poids.errors"
                                class="invalid-feedback"
                                >
                                <div *ngIf="f.poids.errors.required">
                                    Ce champ est obligatoire.
                                </div>
                                <div *ngIf="f.taille.errors.tailleInvalid">
                                  Le taille doit être comprise entre 20 kg et 300 kg.
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label>Groupe Sanguin:</label>
                                <ng-select [items]="groupeSanguins"
                                class="form-control "
                                [closeOnSelect]="true"
                                formControlName="groupeSanguin"
                                [(ngModel)]="patient.groupeSanguin"
                                [ngClass]="{ 'is-invalid': submitted && f.groupeSanguin.errors }"
                                >
                           </ng-select>
                            <div
                            *ngIf="submitted && f.groupeSanguin.errors"
                            class="invalid-feedback"
                            >
                            <div *ngIf="f.groupeSanguin.errors.required">
                                Ce champ est obligatoire.
                            </div>
                            </div>
                                </div>
                        </div>
         </div>
                    <div class="form-group text-center mt-2">
                        <button (click)="onReset()" class="btn btn-secondary mr-3">Annuler</button>
                        <button type="submit" class="btn btn-primary">Enregistrer</button>
                    </div>
    </form>

</div>
</div>
       