import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RdvService {
private _rdvURL=`${environment.baseUrl}/rdvs`
  constructor(private http: HttpClient) { }

  getAllRdvByCurrentPatient():Observable<any>
  {
    return this.http.get<any>(`${this._rdvURL}/get/AllRdvByCurrentPatient`);
  } 
  getRdvById(id: string): Observable<any> {
    return this.http.get<any>(`${this._rdvURL}/${id}`);
  }
  cancelRdv(id: string): Observable<any> {
    return this.http.put<any>(`${this._rdvURL}/annulation/rdv/${id}`, null);
  }
  addRdvByPatient(rdv: any): Observable<any> {
    return this.http.post<any>(`${this._rdvURL}/addRdvByPatient`, rdv);
  }
  updateRdv(id: string, rdvData: any): Observable<any> {
    return this.http.put<any>(`${this._rdvURL}/updateRdv/${id}`, rdvData);
  }
}


