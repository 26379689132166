<div *ngIf="user">   
    <div class="form-row">
        <div class="col">
            <div class="form-group">
                <label>ID patient:</label>
                <input  type="text" [(ngModel)]="user.idPatient" disabled class="form-control" style="
                    background-color: #e9f5f9;
                    color: #039be5;
                    border: solid 1px #e9f5f9;
                    font-weight: bold;
                " >
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>Nom:</label>
                <input style="text-transform: capitalize;" type="text" [(ngModel)]="user.nom" disabled class="form-control" >
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>Prénom:</label>
                <input style="text-transform: capitalize;" type="text" [(ngModel)]="user.prenom" disabled class="form-control" >
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col">
            <div class="form-group">
                <label>Date de naisance:</label>
                <input type="date" [(ngModel)]="user.dateAnniversaire" disabled class="form-control" >
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Age:</label>
                <input type="text" [(ngModel)]="age" disabled class="form-control" >
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col">
            <div class="form-group">
                <label>Email:</label>
                <input type="text" [(ngModel)]="user.email" disabled class="form-control" >
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>
                    Téléphone:</label>
                <input type="text" [(ngModel)]="user.tel" disabled class="form-control" >
            </div>
        </div>
    
    </div>

    <div class="form-row">
        <div class="col-6">
            <div class="form-group">
                <label>Profession:</label>
                <input type="text" [(ngModel)]="user.profession" disabled class="form-control">
            </div>
        </div>

    </div>
    <div class="form-row">
        <div class="col">
        <div class="form-group">
            <label>
                Sexe:</label>
            <input type="text"  [(ngModel)]="user.sexe" disabled class="form-control" >
        </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label>
                    Statut familial:</label>
                <input type="text" [(ngModel)]="user.statusFamilial" disabled class="form-control" >
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-6">
            <div class="form-group">
                <label>
                    Type d'organisme:</label>
                <input type="text" [(ngModel)]="user.typeOrg" disabled class="form-control" >
            </div>
        </div>
    </div>
    <div class="form-group text-center mt-3">
        <button type="submit" (click)="updateProfil()" class="btn btn-primary">Modifier mes informations</button>
    </div>
</div>

