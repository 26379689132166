<div class="modal-header">
    <h5 class="modal-title">Partager le document avec vos médecins</h5>
    <button
      type="button"
      class="btn-close exit-button"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ><i class="fa-regular fa-circle-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
                <div *ngIf="medecinsTraitants && medecinsTraitants.length > 0">
                    <!-- <div class="card-body"> -->
                        <div [ngClass]="sizeContent > 3 ? 'scroller' : 'scroller2'" class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr class="ligne-impaire">
                                        <th scope="col">Médecin</th>
                                        <th scope="col">Spécialité</th>
                                        <!-- <th scope="col" class="text-center">Actions</th> -->
                                    </tr>
                                </thead>
                                <tbody *ngFor="let medecin of medecinsTraitants ; let k = index">
                                    <tr [class.ligne-impaire]="k % 2 !== 0" [class.ligne-paire]="k % 2 === 0" >
                                        <td>Dr. {{medecin.nom}} {{medecin.prenom}}</td>
                                        <td><span class="badge badge-blue">{{medecin.specialite}}</span></td>
                                        <td class="text-center">
                                            <!-- <a><i class="fa-solid fa-calendar-days"style="color: #039be5;"></i></a> -->
                                             <input type="checkbox"
                                              (change)="getLiStDoctorsToShare(medecin)"
                                              [checked]="listMedecinsPartages.includes(medecin._id)"
                                              >
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    <!-- </div> -->
                    <div class="form-group text-center mt-2">
                        <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary mr-3">Annuler</button>
                        <button (click)="shareDocment()" type="submit" class="btn btn-primary">Partager</button>
                    </div>
                </div>
                <div class="card p-5" *ngIf="!medecinsTraitants || medecinsTraitants.length === 0">
                <div class="card-body text-center">
                    <p class="aucun">Vous n'avez pas des médecin Traitants</p>
                </div>
            </div>

    </div>
    </div>    
