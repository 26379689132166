import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private imcUpdatedSource = new Subject<number>();  
  private data: any;


  imcUpdated$ = this.imcUpdatedSource.asObservable();

  updateImc(imc: number) {
    this.imcUpdatedSource.next(imc);
  }

  setData(data: any): void {
    this.data = data;
  }

  getData(): any {
    return this.data;
  }
}
