<div class="accordion-container">
    <div class="card">
        <div class="card-header" (click)="toggleCard(0)">
            <h5><i class="fa-solid fa-disease"></i> Maladies chroniques</h5>
        </div>
        <div class="card-body" [class.hidden]="!cardStates[0]">
            <div *ngIf="maladiesChroniques && maladiesChroniques.length > 0" class="row">
                <div *ngFor="let maladie of maladiesChroniques" class="col-md-3">
                    <div class="card card-custom p-2 d-flex flex-column justify-content-center align-items-center">
                        <div class="card-body">
                            <h5 class="text-center">{{maladie}}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="maladiesChroniques && maladiesChroniques.length > 0" class="d-flex justify-content-end mt-2">
                <button class="btn btn-circle" (click)="addMaladieChronoique()" >
                    <i style="color:#0090b2 ;" class="fa fa-edit"></i>
                </button>
            </div>
            <div *ngIf="maladiesChroniques && maladiesChroniques.length === 0" class="w-100 h-100">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <p style="color: #0090b2; font-weight: 500; font-style: italic;font-size: 1.3rem;">Vous n'avez pas aucune maladie chronique.</p>
                    <button class="btn btn-circle" (click)="addMaladieChronoique()"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" (click)="toggleCard(1)">
            <h5><i class="fa-solid fa-hand-dots"></i> Allergies</h5>
        </div>
        <div class="card-body" [class.hidden]="!cardStates[1]">
            <div *ngIf="allergies && allergies.length > 0" class="row equal-height-cards d-flex align-items-stretch">
                <div *ngFor="let allergie of allergies" class="col-md-3 mb-3">
                    <div class="card card-custom p-2 h-100 d-flex flex-column">
                        <div class="card-body d-flex flex-column justify-content-center">
                            <h5 class="text-center mb-3">{{allergie.typeAllergie}}</h5>
                            <div *ngFor="let nature of allergie.nature " class="row justify-content-center">
                                <span class="badge bg-blue mr-2 mb-2">{{nature}}</span>
                            </div>
                            <div class="row justify-content-center">
                                <button class="btn btn-circle" (click)="editNatureAllergie(allergie.typeAllergie,allergie.nature)" >
                                    <i style="color:#0090b2 ;" class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-circle" (click)="deleteAllergie(allergie.typeAllergie)" >
                                    <i style="color:#0090b2 ;" class="fa fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="allergies && allergies.length > 0" class="d-flex justify-content-end mt-2">
                <button class="btn btn-circle" (click)="addAllergies()" >
                    <i class="fa fa-edit"></i>
                </button>
            </div> -->
            <div *ngIf="allergies && allergies.length === 0" class=" w-100 h-100">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <p style="color: #0090b2; font-weight: 500; font-style: italic;font-size: 1.3rem;">Vous n'avez pas aucun type d'allergie.</p>
                    <button class="btn btn-circle" (click)="addAllergies()"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>
    </div>


    <div class="card">
        <div class="card-header" (click)="toggleCard(2)">
            <h5><i class="fa-solid fa-syringe"></i> Vaccinations</h5>
        </div>
        <div class="card-body" [class.hidden]="!cardStates[2]">
            <div *ngIf="vaccins && vaccins.length > 0" [ngClass]="sizeContent > 4 ? 'scroller' : 'scroller2'"
                class="table-responsive">
                <div>
                    <div>
                        <table class="table">
                            <!-- En-tête du tableau -->
                            <thead>
                                <tr class="ligne-paire">
                                    <th class="text-left" scope="col" style="color:#039be5;">Vaccin(s)</th>
                                    <!-- <th class="text-center" scope="col" style="color:#039be5;">Date de vaccination</th> -->
                                    <!-- <th class="text-center" scope="col" style="color:#039be5;">Nombre des doses</th> -->
                                </tr>
                            </thead>
                            <!-- Corps du tableau -->
                            <tbody *ngFor="let vaccin of vaccins; let k = index">
                                <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0">
                                    <td class="text-left">{{vaccin}}</td>
                                    <!-- <td class="text-center">
                                        <span class="badge bg-blue mr-2">{{vaccin.date}}</span>
                                    </td> -->
                                    <!-- <td class="text-center">{{ vaccin.nbrDose}}</td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="vaccins && vaccins.length > 0" class="d-flex justify-content-end mt-2">
                <button class="btn btn-circle" (click)="addVaccinations()" >
                    <i style="color:#0090b2 ;" class="fa fa-edit"></i>
                </button>
            </div>
            <!-- Définition du cas où il n'y a pas de rendez-vous -->
            <div *ngIf="vaccins && vaccins.length === 0" class="w-100 h-100">
                <div class=" d-flex flex-column align-items-center justify-content-center">
                    <p style="color: #0090b2; font-weight: 500; font-style: italic;font-size: 1.3rem;">Vous n'avez pas des vaccins enregistré.</p>
                    <button class="btn btn-circle" (click)="addVaccinations()"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="card">
        <div class="card-header" (click)="toggleCard(3)">
            <h5><i class="fa-solid fa-pills"></i> Médicaments en cours</h5>
        </div>
        <div class="card-body" [class.hidden]="!cardStates[3]">
            <div *ngIf="medicaments && medicaments.length > 0" class="row">
                <div *ngFor="let medicament of medicaments" class="col-md-3 mb-3">
                    <div class="card card-custom p-2 h-100 d-flex flex-column">
                        <div class="card-body d-flex flex-column justify-content-center">
                            <h5 class="text-center mb-3"><i class="fa-solid fa-tablets"></i>
                                {{medicament.nomMedicamenet}}</h5>
                            <div class="row justify-content-center">
                                <span class="badge bg-blue p-1 mr-2">{{medicament.nbrDose}}</span>
                                <span class="badge bg-blue p-1">{{medicament.duree}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="medicaments && medicaments.length === 0" class="w-100 h-100">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <p style="color: #0090b2; font-weight: 500; font-style: italic;font-size: 1.3rem;">Vous n'avez pas aucun traitemant à suivre.</p>
                    <!-- <button class="btn btn-circle" (click)="addMedicament()"><i class="fa fa-plus"></i></button> -->
                </div>
            </div>
        </div>
    </div>

</div>