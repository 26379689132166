<div class="modal-header">
  <h5 class="modal-title">Annulation de rendez-vous</h5>
  <button
    type="button"
    class="btn-close exit-button"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ><i class="fa-regular fa-circle-xmark"></i>
  </button>
</div>

<div class="modal-body">
  <div class="text-center" *ngIf="rdvDetails">
    <i class="fa-solid fa-calendar-xmark mb-3" style="font-size: 5rem; color:#ff6573 ;"></i>
    <p>Voulez-vous vraiment annuler votre rendez-vous planifié le <strong> {{ rdvDetails.start | date:'dd/MM/yyyy' }}</strong> à <strong> {{ rdvDetails.start | date:'HH:mm' }}</strong> avec Dr. <strong>{{ rdvDetails.medecinId.prenom }} {{ rdvDetails.medecinId.nom }}</strong> ?</p>
  </div>
  <div class="form-group text-center mt-2">
    <button (click)="cancel()" type="reset" class="btn btn-secondary mr-3">Non</button>
    <button (click)="confirmCancel()" type="submit" class="btn btn-primary">Oui</button>
  </div>
</div>
