import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { RdvService } from 'src/app/_services/rdv.service';
import { EventService } from 'src/app/_services/event.service';

@Component({
  selector: 'app-popup-add-appointment',
  templateUrl: './popup-add-appointment.component.html',
  styleUrls: ['./popup-add-appointment.component.css']
})
export class PopupAddAppointmentComponent implements OnInit {
  @Output() addRdv = new EventEmitter<void>();
  currentYear: number;
  currentDate: Date;
  calendarDays: any[] = [];
  timeSlots: any[] = [];
  dateToSend: any;
  timeToSend: any;
  rdv: any = {};
  medecin: any;
  dateSelected: boolean = false;
  timeSelected: boolean = false;
  Motifs = ['Motif1','Motif2','Motif3','Motif4']
  selectedMotif: string;

  constructor(
    public modal: NgbActiveModal,
    private rdvService: RdvService,
    private eventService: EventService,
    public iziToast: Ng2IzitoastService,
    private router: Router
  ) {
    this.currentYear = new Date().getFullYear();
    this.currentDate = new Date();
  }

  ngOnInit(): void {
    this.generateCalendar(); // Generate calendar starting from today
  }

  cancel() {
    this.modal.dismiss();
  }

  previousMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.generateCalendar();
  }

  nextMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.generateCalendar();
  }

  previousWeek() {
    this.currentDate.setDate(this.currentDate.getDate() - 7);
    this.generateCalendar();
  }

  nextWeek() {
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.generateCalendar();
  }

  selectDay(day) {
    this.dateToSend = day.date;
    if (!day.isPast && day.isAvailable) {
      this.calendarDays.forEach(d => d.active = false);
      day.active = true;
      this.dateSelected = true; // Display time selection
      
      // Clear previous time selection and hide buttons
      this.timeSlots = [];
      this.timeSelected = false;
      this.timeToSend = null;
      
      this.fetchAvailableSlots(day.date); // Fetch available slots for the selected date
    }
  }

  selectTimeSlot(slot) {
    this.timeSlots.forEach(s => s.active = false);
    slot.active = true;
    this.timeToSend = slot;
    this.timeSelected = true; // Display buttons
  }

  generateCalendar() {
    const startOfWeek = new Date(this.currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday of the current week

    this.calendarDays = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeek);
      date.setDate(date.getDate() + i);
      this.calendarDays.push({
        dayName: date.toLocaleString('default', { weekday: 'short' }),
        dayNumber: date.getDate(),
        monthName: date.toLocaleString('default', { month: 'short' }),
        date: date,
        isPast: date < new Date() && date.toDateString() !== new Date().toDateString(),
        isAvailable: true,
        active: false
      });
    }
  }

  fetchAvailableSlots(date: Date) {
    const medecinId = this.medecin._id;
    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    const currentTime = new Date().toTimeString().substring(0, 5); // Get the current time in HH:MM format
  
    this.eventService.getAvailableSlotsByMedecinId(medecinId, formattedDate).subscribe(
      (slots) => {
        this.timeSlots = slots
          .filter(slot => {
            const slotTime = slot.start.split('T')[1].substring(0, 5); // Extract time portion from ISO string
            if (formattedDate === new Date().toISOString().split('T')[0]) {
              // If the selected date is today, filter out past time slots
              return slot.libre && slotTime >= currentTime;
            }
            return slot.libre;
          })
          .map(slot => ({
            id: slot._id, // Include the id of the slot
            time: slot.start.split('T')[1].substring(0, 5), // Extract time portion from ISO string
            active: false
          }))
          .sort((a, b) => a.time.localeCompare(b.time)); // Sort slots by time
      },
      (error) => {
        this.iziToast.error({
          message: 'Erreur lors de la récupération des créneaux disponibles',
          position: 'topRight'
        });
      }
    );
  }

  saveRdv() {
    if (this.dateToSend && this.timeToSend && this.selectedMotif) {
      const date = new Date(this.dateToSend);
  
      if (!isNaN(date.getTime())) {
        const year = date.getFullYear();
        const month = this.padZero(date.getMonth() + 1);
        const day = this.padZero(date.getDate());
        const formattedDate = `${year}-${month}-${day}`;
  
        const time = this.timeToSend.time;
        const start = `${formattedDate}T${time}:00`;
        this.rdv.start = start;
        this.rdv.medecinId = this.medecin._id;
        this.rdv.timeSlotId = this.timeToSend.id;
        this.rdv.motif = [this.selectedMotif];
      }
  
      this.rdvService.addRdvByPatient(this.rdv).subscribe(
        (data) => {
          this.iziToast.show({
            message: 'Rendez-vous ajouté avec succès',
            messageColor: '#386641',
            progressBarColor: '#6a994e',
            icon: 'bi-check-circle',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#dde5b6',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
          });
          this.addRdv.emit();
          this.modal.close();
          this.router.navigate(['patient/rendez-vous']);
        }
      );
      this.eventService.toggleLibre(this.timeToSend.id).subscribe(data=>{
      })
    }
  }
  

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
