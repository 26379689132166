import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  loading = false;
  error :boolean = false;
  eroorMessage :string;
  closeResult: string;
  RememberMe = false 


  constructor(private formBuilder: FormBuilder,
    private router :Router,private autenticationService :AuthenticationService,
    private tokenStorage :TokenStorageService,
    private userService :UserService,
    public iziToast: Ng2IzitoastService,

  
    ) { }

  ngOnInit() {
      this.registerForm = this.formBuilder.group({
          
         
          email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
          password: ['', [Validators.required]],
      }, {
      });
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    let role; 
      this.submitted = true;
      if (this.registerForm.invalid) {
          return;
      }
      this.autenticationService.loginUser(this.registerForm.value).subscribe(
        (data) => {
          role =data["patient"].role;
          localStorage.setItem('token',data["token"])
          localStorage.setItem('user',data["patient"].id)
          // if(role == "admin")
          // {
            this.router.navigate(['/patient'])
            this.tokenStorage.saveUser(data);
          // }
           
        },
        (error) => {
          this.loading = false;
          this.error=true
          this.eroorMessage=error.error
          this.iziToast.show({
            message:"Merci de bien vérifier vos informations.",
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',	
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            //overlay:true,
            overlayClose:true,	
           });
        }

      );
  }
go()
{
  this.registerForm.reset();
}
  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }    

  rememberMe()
  {
   this.userService.stayConnected(this.registerForm.value.email).subscribe(data =>{
   })
  }


}
