import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AuthGuard } from './_guards/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from 'src/environments/environment';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TokenInterceptorService } from './_services/token-interceptor.service';
import { ResetPassowrdComponent } from './components/reset-passowrd/reset-passowrd.component';
import { UpdatePassowrdComponent } from './components/update-passowrd/update-passowrd.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SortPipe } from './_helpers/sort.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SearchPipe } from './_helpers/search.pipe';
import { DashboardComponent } from './patient/dashboard/dashboard.component';
import { HeaderComponent } from './_shared/header/header.component';
import { FooterComponent } from './_shared/footer/footer.component';
import { MedecinComponent } from './patient/medecin/medecin.component';
import { DocumentsComponent } from './patient/files/documents/documents.component';
import { CalendarComponent } from './patient/calendar/calendar.component';
import { RdvsComponent } from './patient/rdvs/rdvs.component';
import { AddRdvComponent } from './patient/rdvs/add-rdv/add-rdv.component';
import { UpdateRdvComponent } from './patient/rdvs/update-rdv/update-rdv.component';
import { ProfilComponent } from './patient/profil/profil.component';
import { CertificatComponent } from './patient/files/certificat/certificat.component';
import { OrdonnanceComponent } from './patient/files/ordonnance/ordonnance.component';
import { FillInscriptionComponent } from './components/fill-inscription/fill-inscription.component';
import { GeneralInfoFormComponent } from './patient/profil/general-info-form/general-info-form.component';
import { PasswordFormComponent } from './patient/profil/password-form/password-form.component';
import { HealthDetailsFormComponent } from './patient/profil/health-details-form/health-details-form.component';
import { PopupUpdateProfilPatientComponent } from './popus/popup-update-profil-patient/popup-update-profil-patient.component';
import { AddDocumentComponent } from './patient/files/documents/add-document/add-document.component';
import { PopupAddMaladieChroniqueComponent } from './popus/dossierMedical/popup-add-maladie-chronique/popup-add-maladie-chronique.component';
import { PopupAddAllergiesComponent } from './popus/dossierMedical/popup-add-allergies/popup-add-allergies.component';
import { PopupAddVaccinationsComponent } from './popus/dossierMedical/popup-add-vaccinations/popup-add-vaccinations.component';
import { PopupAddMedicamentComponent } from './popus/dossierMedical/popup-add-medicament/popup-add-medicament.component';
import { PopupDeleteAllergieComponent } from './popus/dossierMedical/popup-delete-allergie/popup-delete-allergie.component';
import { PopupEditNatureAllergieComponent } from './popus/dossierMedical/popup-edit-nature-allergie/popup-edit-nature-allergie.component';
import { ValidationMedecinComponent } from './patient/validation-medecin/validation-medecin.component';
import { PopupAddAppointmentComponent } from './popus/rendez-vous/popup-add-appointment/popup-add-appointment.component';
import { PopupUpdateAppointmentComponent } from './popus/rendez-vous/popup-update-appointment/popup-update-appointment.component';
import { PopupCancelAppointmentComponent } from './popus/rendez-vous/popup-cancel-appointment/popup-cancel-appointment.component';
import { PopupListDocumentsComponent } from './popus/documents/popup-list-documents/popup-list-documents.component';
import { PopupPartageDocumentComponent } from './popus/documents/popup-partage-document/popup-partage-document.component';
import { UpdateDocumentComponent } from './patient/files/documents/update-document/update-document.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPassowrdComponent,
    UpdatePassowrdComponent,
    SortPipe,
    SearchPipe,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    MedecinComponent,
    DocumentsComponent,
    CalendarComponent,
    RdvsComponent,
    AddRdvComponent,
    UpdateRdvComponent,
    ProfilComponent,
    CertificatComponent,
    OrdonnanceComponent,
    FillInscriptionComponent,
    GeneralInfoFormComponent,
    PasswordFormComponent,
    HealthDetailsFormComponent,
    PopupUpdateProfilPatientComponent,
    AddDocumentComponent,
    PopupAddMaladieChroniqueComponent,
    PopupAddAllergiesComponent,
    PopupAddVaccinationsComponent,
    PopupAddMedicamentComponent,
    PopupDeleteAllergieComponent,
    PopupEditNatureAllergieComponent,
    ValidationMedecinComponent,
    PopupAddAppointmentComponent,
    PopupUpdateAppointmentComponent,
    PopupCancelAppointmentComponent,
    PopupListDocumentsComponent,
    PopupPartageDocumentComponent,
    UpdateDocumentComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiSwitchModule.forRoot({
      color: '#0e309f',
      
     
    }),
    HttpClientModule,
    AngularEditorModule,
    NgxIntlTelInputModule,
    RecaptchaV3Module,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    BrowserAnimationsModule,
    Ng2IziToastModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgxFileDropModule,
  ],
  providers: [AuthGuard,{
    provide :HTTP_INTERCEPTORS,
    useClass :TokenInterceptorService,
    multi:true
  },
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptcha.siteKey,
  },],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
